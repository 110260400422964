import React, { FC } from 'react'
import NameCell from '../tableCells/NameCell'
import DateCell from '../tableCells/DateCell'
import DetailCell from '../tableCells/DetailCell'
import ValuationCell from '../tableCells/ValuationCell'
import TypeCell from '../tableCells/TypeCell'
import AddressCell from '../tableCells/AddressCell'
import StatusCell from '../tableCells/StatusCell'
import LeadStatus from '../../leadStatus/LeadStatus'
import {
  formatDate,
  formatValuation,
  getPropertyTypeIcon,
  getLeadTypeIcon,
  getFullAddressStr,
  getShortAddressStr,
  getSolarLeadPLZ,
} from '../../../helpers/lead'
import { type Lead } from '../../../types/lead'
import IconWithToolTip from '../../iconWithTooltip/IconWithToolTip'
import { LeadType } from '../../../enums/LeadType'
import Badge from '../../badge/Badge'

type LeadRowProps = {
  lead: Lead
  index: number
  hideLead: boolean
  fadeOut: boolean
  onClick: (index: number) => void
}

const LeadRow: FC<LeadRowProps> = ({ lead, index, hideLead, fadeOut, onClick }) => {
  const type = lead.leadType
  const cockpitName = lead.cockpitName
  const realEstate = lead.realEstate
  const requester = lead.requester
  const createdDate = formatDate(lead.createdDate)
  const gdprDeleted = lead.gdprDeleted

  const status = lead['status'] ? lead['status'] : ''
  return (
    <div
      data-testid={`lapAndDesk-lead-${index}`}
      className={`tableLine ${hideLead ? 'hide' : ''} ${fadeOut ? 'fade-out' : ''}`}
      key={index}
      onClick={() => onClick(index)}
    >
      <NameCell>
        <div className='multilineContent'>
          <div className='nameLabel'>
            {gdprDeleted ? requester.firstName : `${requester.firstName} ${requester.lastName}`}
          </div>
        </div>
      </NameCell>

      <DetailCell>
        <IconWithToolTip icon={getLeadTypeIcon(type)} tooltip={cockpitName} nowrap={true} />
      </DetailCell>

      <DateCell>
        <span>{createdDate}</span>
      </DateCell>

      <TypeCell>
        <IconWithToolTip icon={getPropertyTypeIcon(realEstate.realEstateType)} tooltip={realEstate.realEstateType} />
      </TypeCell>

      <ValuationCell>
        <span>{formatValuation(type, realEstate.valuation)}&nbsp;&#8364;</span>
      </ValuationCell>

      <AddressCell>
        <div className='font-ellipsis palm-hide'>
          {type === LeadType.BUYER ||
          type === LeadType.HEATING ||
          type === LeadType.ENERGY_ADVISOR ||
          type === LeadType.WALL_WINDOW ||
          type === LeadType.ROOF_WINDOW
            ? getShortAddressStr(lead)
            : type === LeadType.SOLAR
            ? getSolarLeadPLZ(lead)
            : getFullAddressStr(lead)}
        </div>
        <div className='font-ellipsis desk-hide lap-hide'>{getShortAddressStr(lead)}</div>
      </AddressCell>

      <StatusCell>
        {type === LeadType.ENERGY_ADVISOR && lead.payPerConvertedIsInvoiced ? (
          <Badge className='margin-right-m badge--teal' testid='lead-status-badge'>
            Konvertiert
          </Badge>
        ) : (
          <LeadStatus value={status} />
        )}
      </StatusCell>
    </div>
  )
}

export default LeadRow
