import config, { isDevelopmentMode, isFunctionalTest } from '../config'
import isomorphicFetch from 'isomorphic-fetch'

export const doRecaptcha = () => {
  window.onloadRecaptcha = () => {
    if (!isDevelopmentMode() && !isFunctionalTest()) {
      window.grecaptcha.execute(config.reCaptchaSiteKey, { action: 'customerCockpitHomepage' }).then((token) => {
        isomorphicFetch(config.routing.rleApiURL + `/public/recaptcha`, {
          method: 'POST',
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: token }),
        })
      })
    }
  }
}
