export default {
  routing: {
    rleApiURL: 'https://realtor-lead-engine-webapp-tuv.maklersuchmaschine.sandbox-immobilienscout24.de',
  },
  header: {
    baseUrl: 'https://content.header.immobilienscout24.de/main',
  },
  footer: {
    baseUrl: 'https://www.sandbox-immobilienscout24.de',
  },
  oauth: {
    url: 'https://publicauth.sandbox-immobilienscout24.de/oauth/authorize',
    client: 'realtor-lead-engine-customer-cockpit',
    redirect: 'https://anfragen.maklersuchmaschine.sandbox-immobilienscout24.de/',
  },
  manager: {
    url: 'https://manager.maklersuchmaschine.sandbox-immobilienscout24.de/',
  },
  reporting: {
    url: '//tracking.sandbox-immobilienscout24.de/tr.js?cP--countername=is24.de.scoutmanager.rle&cP--svc_module_name=customer-cockpit&addOnClick&initialPageView=false',
    useTealium: true,
  },
  reCaptchaSiteKey: '6LdhitgUAAAAAL1sYzDupHx1VnvRZI9pC-BPBGaE',
}
