export enum LeadType {
  BASIC = 'BASIC',
  RICH = 'RICH',
  EXCLUSIVE = 'EXCLUSIVE',
  LAND_WITHOUT_REALTOR = 'LAND_WITHOUT_REALTOR',
  WITHOUT_REALTOR = 'WITHOUT_REALTOR',
  FREE_TO_LIST = 'FREE_TO_LIST',
  B = 'B',
  COMMERCIAL = 'COMMERCIAL',
  BUYER = 'BUYER',
  SOLAR = 'SOLAR',
  ENERGY_ADVISOR = 'ENERGY_ADVISOR',
  HEATING = 'HEATING',
  WALL_WINDOW = 'WALL_WINDOW',
  ROOF_WINDOW = 'ROOF_WINDOW',
  LICENSE = 'LICENSE',
}
