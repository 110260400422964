import React, { Fragment } from 'react'
import { LeadType } from '../../enums/LeadType'
import { formatValuation } from '../../helpers/lead'
import {
  Basement,
  BatteryStorageType,
  DevelopmentPotential,
  DevelopmentStatus,
  EnergySourceType,
  Facility,
  HeatingType,
  HouseholdSizeType,
  InsulationType,
  LandLayout,
  type LeadData,
  MonumentProtectionType,
  OwnerType,
  PlacementType,
  type RealEstate,
  RealEstateSubType,
  RealEstateType,
  type Requester,
  RequesterType,
  ResidentialUsageType,
  RoofType,
  Salutation,
  SolarUserIntent,
  UsageStatus,
  UserIntent,
  WallboxType,
  WindowAmount,
  WindowDisposal,
  WindowPaneCount,
} from '../../types/lead'
import { numberFormatter } from '../../helpers/numberFormatter'

export interface Row {
  name: string
  value: string | null | undefined
}

export interface DataSet {
  label: string
  rows: Array<Row>
}

const capitalize = (input: string | null | undefined): string | null | undefined => {
  return input && input.charAt(0).toUpperCase() + input.slice(1)
}

const has = (field?: string | null): string | null | undefined => {
  return field && (field === '' ? 'Nein' : capitalize(field))
}

const booleanToString = (field: boolean): string => (field ? 'Ja' : 'Nein')

const removeEmpty = (rows: Array<Row>): Array<Row> => {
  return rows.filter(
    (row) => row.value !== null && row.value !== undefined && (!row.value.trim || row.value.trim() !== ''),
  )
}

export const getSalutation = (salutation: Salutation): string => {
  switch (salutation) {
    case Salutation.MALE:
      return 'Herr'
    case Salutation.FEMALE:
      return 'Frau'
    case Salutation.DIVERS:
      return ''
  }
}

const formatUserIntent = (userIntent: UserIntent): string | null => {
  switch (userIntent) {
    case UserIntent.MAKLER_SALE:
      return 'Verkauf'
    case UserIntent.RENT:
      return 'Miete'
    case UserIntent.BUY:
      return 'Kauf'
    case UserIntent.LET:
      return 'Vermietung'
    default:
      return null
  }
}

const formatSolarUserIntent = (userIntent: SolarUserIntent | null | undefined): string | null => {
  switch (userIntent) {
    case SolarUserIntent.RENT:
      return 'Miete'
    case SolarUserIntent.BUY:
      return 'Kauf'
    case SolarUserIntent.BOTH:
      return 'Beides interessant'
    case SolarUserIntent.NOT_SURE:
      return 'Weiß ich nicht'
    default:
      return null
  }
}

export interface BasicLeadData {
  phone: string | null
  name: string | null
  email: string | null
  objectDetails: {
    address: string
    data: DataSet[]
    extendedData: Array<Row>
  }
}

const getSolarLeadData = (
  gdprDeleted: boolean,
  requester: Requester,
  leadData: LeadData,
  realEstate: RealEstate,
): RichLeadData => ({
  name: gdprDeleted
    ? `${requester.firstName}`
    : `${getSalutation(requester.salutation)} ${requester.firstName} ${requester.lastName}`,
  phone: requester.phoneNumber,
  email: requester.email,
  userIntent: formatSolarUserIntent(leadData.userIntent as unknown as SolarUserIntent),
  phoneAlternative: '',
  isOwner: getOwnerType(leadData.ownerType),
  availability: leadData.ownerAvailability == null ? '' : leadData.ownerAvailability,
  contactAvailability: leadData.contactAvailability == null ? '' : leadData.contactAvailability,
  objectDetails: {
    address:
      realEstate.address.postcode === undefined
        ? `PLZ ${leadData.solarLeadData?.postcode}` // early leads did not have data in real estate
        : renderAddressForEsgLeads(gdprDeleted, realEstate),
    data: [
      {
        label: 'Anfrageinformationen',
        rows: removeEmpty([
          {
            name: 'Objektart',
            value: formatPlacementType(leadData.solarLeadData?.placement),
          },
          {
            name: 'Finanzierung',
            value: formatSolarUserIntent(leadData.solarLeadData?.userIntent),
          },
          {
            name: 'Batteriespeichersystem',
            value: formatBatteryStorageType(leadData.solarLeadData?.batteryStorage),
          },
          {
            name: 'Wallbox',
            value: formatWallboxType(leadData.solarLeadData?.wallBox),
          },
          {
            name: 'Haushaltsgröße',
            value: formatHouseholdSizeType(leadData.solarLeadData?.householdSize),
          },
          {
            name: 'Jährlicher Stromverbrauch',
            value:
              leadData.solarLeadData?.energyConsumption == null
                ? null
                : `${numberFormatter.format(leadData.solarLeadData?.energyConsumption)} kWh`,
          },
        ]),
      },
    ],
    extendedData: [],
  },
})

const getEnergyAdvisorLeadData = (
  gdprDeleted: boolean,
  requester: Requester,
  leadData: LeadData,
  realEstate: RealEstate,
): RichLeadData => {
  const data: DataSet[] = []

  if (leadData.energyAdvisorLeadData?.eligibleForDiscount) {
    data.push({
      label: 'Dieser Nutzer hat Anspruch auf einen Rabatt',
      rows: removeEmpty([
        {
          name: 'Rabatt Betrag',
          value: leadData.energyAdvisorLeadData?.eligibleForDiscount ? '50 Euro' : null,
        },
      ]),
    })
  }

  data.push({
    label: 'Anfrageinformationen',
    rows: removeEmpty([
      {
        name: 'Objektart',
        value: formatPlacementType(leadData.energyAdvisorLeadData?.placement),
      },
      {
        name: 'Denkmalschutz',
        value: formatMonumentProtectionType(leadData.energyAdvisorLeadData?.monumentProtection),
      },
      {
        name: '> 50% Wohn Nutzung',
        value: formatResidentialUsageType(leadData.energyAdvisorLeadData?.residentialUsage),
      },
      {
        name: 'Baujahr',
        value: realEstate.constructionYear == null ? null : `${realEstate.constructionYear}`,
      },
    ]),
  })

  return {
    name: gdprDeleted
      ? `${requester.firstName}`
      : `${getSalutation(requester.salutation)} ${requester.firstName} ${requester.lastName}`,
    phone: requester.phoneNumber,
    email: requester.email,
    phoneAlternative: '',
    isOwner: getOwnerType(leadData.ownerType),
    availability: leadData.ownerAvailability == null ? '' : leadData.ownerAvailability,
    contactAvailability: leadData.contactAvailability == null ? '' : leadData.contactAvailability,
    objectDetails: {
      address: renderAddressForEsgLeads(gdprDeleted, realEstate),
      data: data,
      extendedData: [],
    },
  }
}

function renderAddressForEsgLeads(gdprDeleted: boolean, realEstate: RealEstate) {
  return gdprDeleted ? (
    `${realEstate.address.postcode} ${realEstate.address.city}`
  ) : (
    <Fragment>
      {realEstate.address.street == null || realEstate.address.houseNumber == null ? (
        ''
      ) : (
        <Fragment>
          {realEstate.address.street} {realEstate.address.houseNumber}
          {', '}
        </Fragment>
      )}
      {realEstate.address.postcode} {realEstate.address.city}
    </Fragment>
  )
}

const getHeatingLeadData = (
  gdprDeleted: boolean,
  requester: Requester,
  leadData: LeadData,
  realEstate: RealEstate,
): RichLeadData => ({
  name: gdprDeleted
    ? `${requester.firstName}`
    : `${getSalutation(requester.salutation)} ${requester.firstName} ${requester.lastName}`,
  phone: requester.phoneNumber,
  email: requester.email,
  phoneAlternative: '',
  isOwner: getOwnerType(leadData.ownerType),
  availability: leadData.ownerAvailability == null ? '' : leadData.ownerAvailability,
  contactAvailability: leadData.contactAvailability == null ? '' : leadData.contactAvailability,
  objectDetails: {
    address: renderAddressForEsgLeads(gdprDeleted, realEstate),
    data: [
      {
        label: 'Anfrageinformationen',
        rows: removeEmpty([
          {
            name: 'Objektart',
            value: formatPlacementType(leadData.heatingLeadData?.placement),
          },
          {
            name: 'Baujahr',
            value: realEstate.constructionYear == null ? null : `${realEstate.constructionYear}`,
          },
          {
            name: 'Modernisiert (20 Jahre)',
            value: formatInsulationType(leadData.heatingLeadData?.insulation),
          },
          {
            name: 'Energieträger',
            value: formatEnergySource(leadData.heatingLeadData?.energySource),
          },
          {
            name: 'Heizungstyp',
            value: formatHeatingType(leadData.heatingLeadData?.heating),
          },
          {
            name: 'Wohnfläche',
            value:
              leadData?.heatingLeadData?.heatedSpaceSize == null
                ? ''
                : `${numberFormatter.format(leadData.heatingLeadData?.heatedSpaceSize)} qm`,
          },
          {
            name: 'Baujahr der Heizung',
            value:
              leadData.heatingLeadData?.heatingInstallationYear == null
                ? null
                : `${leadData.heatingLeadData?.heatingInstallationYear}`,
          },
        ]),
      },
    ],
    extendedData: [],
  },
})

const getWallWindowLeadData = (
  gdprDeleted: boolean,
  requester: Requester,
  leadData: LeadData,
  realEstate: RealEstate,
): RichLeadData => ({
  name: gdprDeleted
    ? `${requester.firstName}`
    : `${getSalutation(requester.salutation)} ${requester.firstName} ${requester.lastName}`,
  phone: requester.phoneNumber,
  email: requester.email,
  phoneAlternative: '',
  isOwner: getOwnerType(leadData.ownerType),
  availability: leadData.ownerAvailability == null ? '' : leadData.ownerAvailability,
  contactAvailability: leadData.contactAvailability == null ? '' : leadData.contactAvailability,
  objectDetails: {
    address: renderAddressForEsgLeads(gdprDeleted, realEstate),
    data: [
      {
        label: 'Anfrageinformationen',
        rows: removeEmpty([
          {
            name: 'Art des Anforderers',
            value: formatRequesterType(leadData.wallWindowLeadData?.requesterType),
          },
        ]),
      },
    ],
    extendedData: [],
  },
})

const getRoofWindowLeadData = (
  gdprDeleted: boolean,
  requester: Requester,
  leadData: LeadData,
  realEstate: RealEstate,
): RichLeadData => ({
  name: gdprDeleted
    ? `${requester.firstName}`
    : `${getSalutation(requester.salutation)} ${requester.firstName} ${requester.lastName}`,
  phone: requester.phoneNumber,
  email: requester.email,
  phoneAlternative: '',
  isOwner: getOwnerType(leadData.ownerType),
  availability: leadData.ownerAvailability == null ? '' : leadData.ownerAvailability,
  contactAvailability: leadData.contactAvailability == null ? '' : leadData.contactAvailability,
  objectDetails: {
    address: renderAddressForEsgLeads(gdprDeleted, realEstate),
    data: [
      {
        label: 'Anfrageinformationen',
        rows: removeEmpty([
          {
            name: 'Dachform',
            value: formatRoofType(leadData.roofWindowLeadData?.roofType),
          },
          {
            name: 'Anzahl der Dachfenster',
            value: formatWindowAmount(leadData.roofWindowLeadData?.windowAmount),
          },
          {
            name: 'Isolierung von Dachfenstern',
            value: formatWindowPaneCount(leadData.roofWindowLeadData?.windowPaneCount),
          },
          {
            name: 'Fensterentsorgung',
            value: formatWindowDisposal(leadData.roofWindowLeadData?.windowDisposal),
          },
        ]),
      },
    ],
    extendedData: [],
  },
})

const getBasicLeadData = (
  gdprDeleted: boolean,
  type: string | null,
  requester: Requester,
  realEstate: RealEstate,
): BasicLeadData => ({
  name: gdprDeleted
    ? requester.firstName
    : `${getSalutation(requester.salutation)} ${requester.firstName} ${requester.lastName}`,
  phone: requester.phoneNumber,
  email: requester.email,
  objectDetails: {
    address: `${realEstate.address.postcode} ${realEstate.address.city}`,
    data: [
      {
        label: 'Objektinformationen',
        rows: removeEmpty([
          {
            name: 'Immobilientyp',
            value: getRealEstateTypeDenotation(realEstate.realEstateType),
          },
          {
            name: 'Grundstücksfläche',
            value: realEstate.propertyArea == null ? null : `${numberFormatter.format(realEstate.propertyArea)} qm`,
          },
          {
            name: 'Wohnfläche',
            value: realEstate.livingSpace == null ? null : `${numberFormatter.format(realEstate.livingSpace)} qm`,
          },
          {
            name: 'Zimmer',
            value: realEstate.numberOfRooms == null ? null : `${numberFormatter.format(realEstate.numberOfRooms)}`,
          },
          {
            name: 'Ungefährer Marktwert',
            value: `${formatValuation(type, realEstate.valuation)} EUR`,
          },
        ]),
      },
    ],
    extendedData: [],
  },
})

export interface RichLeadData {
  name: string
  phone: string | null
  phoneAlternative: string | null | undefined
  email: string | null
  isOwner: string | null | undefined
  userIntent?: string | null
  availability: string | null
  contactAvailability: string | null
  objectDetails: {
    address: string | JSX.Element
    data: Array<DataSet>
    extendedData: Array<Row>
  }
}

export function getRealEstateTypeDenotation(realEstateType: RealEstateType): string {
  switch (realEstateType) {
    case RealEstateType.APARTMENT:
      return 'Wohnung'
    case RealEstateType.HOUSE:
      return 'Haus'
    case RealEstateType.LAND:
      return 'Grundstück'
    case RealEstateType.COMMERCIAL:
      return 'Gewerbe'
    case RealEstateType.PACKAGE:
      return 'Immobilienpaket'
    case RealEstateType.GARAGE_PARKING_SPACE:
      return 'PKW-Stellplatz / Garage'
  }
}

export function formatPlacementType(placementType: PlacementType | null | undefined): string {
  switch (placementType) {
    case PlacementType.APARTMENT:
    case PlacementType.ONE_OR_TWO_FAMILY_HOME:
      return 'Ein/Zweifamilienhaus'

    case PlacementType.SINGLE_FAMILY_HOME:
      return 'Einfamilienhaus'
    case PlacementType.TWO_FAMILY_HOME:
      return 'Zweifamilienhaus'

    case PlacementType.HOUSE:
    case PlacementType.MULTI_FAMILY_HOUSE:
      return 'Mehrfamilienhaus'
    case PlacementType.COMMERCIAL:
      return 'Gewerbe'
    default:
      return 'Sonstiges'
  }
}

function getSubType(subType: RealEstateSubType): string | null {
  switch (subType) {
    case RealEstateSubType.BASEMENT:
      return 'Keller'
    case RealEstateSubType.ATTIC:
      return 'Dachgeschoss'
    case RealEstateSubType.GROUND_FLOOR:
      return 'Erdgeschoss'
    case RealEstateSubType.DUPLEX:
      return 'Maisonette'
    case RealEstateSubType.APARTMENT:
      return 'Etagenwohnung'
    case RealEstateSubType.LOFT:
      return 'Loft'
    case RealEstateSubType.SOUTERRAIN:
      return 'Souterrain'
    case RealEstateSubType.DETACHED_HOUSE:
      return 'Einfamilienhaus'
    case RealEstateSubType.SEMI_DETACHED_HOUSE:
      return 'Doppelhaushälfte'
    case RealEstateSubType.TERRACED_MIDDLE_HOUSE:
      return 'Reihenmittelhaus'
    case RealEstateSubType.TERRACED_END_HOUSE:
      return 'Reihenendhaus'
    case RealEstateSubType.MULTI_FAMILY_HOUSE:
      return 'Mehrfamilienhaus'
    case RealEstateSubType.OFFICE:
      return 'Büro oder Praxis'
    case RealEstateSubType.HALL:
      return 'Halle oder Produktion'
    case RealEstateSubType.RETAIL:
      return 'Einzelhandel'
    case RealEstateSubType.GASTRONOMY:
      return 'Gastronomie oder Hotel'
    case RealEstateSubType.MISCELLANEOUS:
      return 'Sonstiges'
    case RealEstateSubType.TWO_OR_THREE_FAMILY_HOUSE:
      return 'Zwei- oder Dreifamilienhaus'
    case RealEstateSubType.BUILDING_PLOT:
      return 'Baugrundstück'
    case RealEstateSubType.COMMERCIAL_LAND:
      return 'Gewerbegrundstück'
    case RealEstateSubType.AGRICULTURAL_LAND:
      return 'Agrarfläche'
    case RealEstateSubType.GARDEN_PLOT:
      return 'Gartengrundstück'
    case RealEstateSubType.BUILDING_LAND:
      return 'Bauerwartungsland'
    case RealEstateSubType.MEADOW:
      return 'Wiese'
    case RealEstateSubType.RESIDENTIAL_AND_COMMERCIAL_BUILDING:
      return 'Wohn- und Geschäftshaus'
    case RealEstateSubType.HOTEL:
      return 'Gastronomie oder Hotel'
    case RealEstateSubType.GASTRONOMY_IV24:
      return 'Gastronomie oder Hotel'
    case RealEstateSubType.FORMER_FARMHOUSE:
      return 'Resthof'
    case RealEstateSubType.COMMERCIAL_PROPERTY:
      return 'Gewerbeimmobilie'
    case RealEstateSubType.CONDOMINIUMS:
      return 'Eigentumswohnungen'
    case RealEstateSubType.GARAGE:
      return 'Garage'
    case RealEstateSubType.UNDERGROUND_PARKING_SPACE:
      return 'Tiefgaragenstellplatz'
    case RealEstateSubType.CARPORT:
      return 'Carport'
    case RealEstateSubType.PARKING_SPACE:
      return 'Stellplatz'
    default:
      return null
  }
}

const getFloor = (floor: number): string => {
  if (floor <= 0) {
    return 'Souterrain/Untergeschoss'
  } else if (floor === 0) {
    return 'Erdgeschoss'
  } else if (floor === 1) {
    return '1. Stockwerk'
  } else if (floor === 2) {
    return '2. Stockwerk'
  } else if (floor === 3) {
    return '3. Stockwerk'
  } else if (floor === 4) {
    return '4. Stockwerk'
  } else if (floor === 5) {
    return '5. Stockwerk'
  } else if (floor === 6) {
    return '6. Stockwerk oder höher'
  } else {
    return ''
  }
}
const getDevelopmentStatus = (developmentStatus: DevelopmentStatus): string => {
  switch (developmentStatus) {
    case DevelopmentStatus.FULL_MEDIA:
      return 'Erschlossen'
    case DevelopmentStatus.PARTIAL_MEDIA:
      return 'Teilerschlossen'
    case DevelopmentStatus.NO_MEDIA:
      return 'Unerschlossen'
  }
}

const getDevelopmentPotential = (developmentPotential: DevelopmentPotential): string => {
  switch (developmentPotential) {
    case DevelopmentPotential.DEVELOPED:
      return 'Kurzfristig bebaubar'
    case DevelopmentPotential.PARTIALLY_DEVELOPED:
      return 'Eingeschränkt bebaubar'
    case DevelopmentPotential.UNDEVELOPED:
      return 'Nicht bebaubar'
    case DevelopmentPotential.UNKNOWN:
      return 'Weiß nicht'
  }
}

const getLandLayout = (landLayout: LandLayout): string => {
  switch (landLayout) {
    case LandLayout.CORNER_PROPERTY:
      return 'Eckgrundstück'
    case LandLayout.RECTANGULAR_FORMAT:
      return 'Rechteckiger Zuschnitt'
    case LandLayout.OTHER:
      return 'Sonstiges'
  }
}

const getUsageStaus = (usageStatus: UsageStatus) => {
  switch (usageStatus) {
    case UsageStatus.OWNER_OCCUPIED:
      return 'Selbstgenutzt'
    case UsageStatus.RENTED:
      return 'Vermietet'
    case UsageStatus.VACANT:
      return 'Leer stehend'
    case UsageStatus.PARTLY_RENTED:
      return 'teilweise vermietet'
  }
}

const getRichLeadObjectInformation = (
  leadType: string | null,
  requester: Requester,
  realEstate: RealEstate,
): Array<Row> => {
  return removeEmpty([
    {
      name: 'Immobilientyp',
      value: getRealEstateTypeDenotation(realEstate.realEstateType),
    },
    {
      name: 'Gebäudeart',
      value: realEstate.subType == null ? null : getSubType(realEstate.subType),
    },
    {
      name: 'Grundstücksfläche',
      value: realEstate.propertyArea == null ? null : `${realEstate.propertyArea} qm`,
    },
    {
      name: 'Grundstückszuschnitt',
      value: realEstate.landLayout == null ? null : getLandLayout(realEstate.landLayout),
    },
    {
      name: 'Bebaubarkeit',
      value: realEstate.developmentPotential == null ? null : getDevelopmentPotential(realEstate.developmentPotential),
    },
    {
      name: 'Erschließung',
      value: realEstate.developmentStatus == null ? null : getDevelopmentStatus(realEstate.developmentStatus),
    },
    {
      name: 'Wohnfläche',
      value: realEstate.livingSpace == null ? null : `${realEstate.livingSpace} qm`,
    },
    {
      name: 'Zimmer',
      value: realEstate.numberOfRooms == null ? null : `${realEstate.numberOfRooms}`,
    },
    {
      name: 'Etage',
      value: realEstate.floor == null ? null : getFloor(realEstate.floor),
    },
    {
      name: 'Baujahr',
      value: realEstate.constructionYear == null ? null : `${realEstate.constructionYear}`,
    },
    {
      name: 'Aktuelle Nutzung',
      value: realEstate.usageStatus == null ? null : getUsageStaus(realEstate.usageStatus),
    },
    {
      name: 'Mtl. Kaltmiete',
      value: realEstate.baseRent == null ? null : `${realEstate.baseRent} EUR`,
    },
    {
      name: 'Etagenanzahl',
      value: realEstate.floorsHouse == null ? null : getFloor(realEstate.floorsHouse),
    },
    {
      name: 'Ungefährer Marktwert',
      value: `${formatValuation(leadType, realEstate.valuation)} EUR`,
    },
  ])
}

const getOwnerType = (ownerType: OwnerType) => {
  switch (ownerType) {
    case OwnerType.YES:
      return 'Ja'
    case OwnerType.NO:
      return 'Nein'
    case OwnerType.CO_OWNER:
      return 'Ich bin Teil-Eigentümer'
    case OwnerType.RELATIVE:
      return 'Ich bin Angehöriger'
  }
}
const getBalconyPatio = (balconyPatio: boolean | null) => {
  if (balconyPatio == null) {
    return ''
  } else if (balconyPatio) {
    return 'Ja'
  } else {
    return 'Nein'
  }
}

const getBasement = (basement: Basement) => {
  switch (basement) {
    case Basement.YES:
      return 'Ja'
    case Basement.NO:
      return 'Nein'
    case Basement.AVAILABLE:
      return 'vorhanden'
    case Basement.PARTIAL_BASEMENT:
      return 'teilunterkellert'
    case Basement.FULL_BASEMENT:
      return 'vollunterkellert'
    case Basement.NOT_AVAILABLE:
      return 'nicht vorhanden'
    case Basement.NOT_SPECIFIED:
      return 'keine Angabe'
  }
}

const formatBatteryStorageType = (type: BatteryStorageType | null | undefined) => {
  switch (type) {
    case BatteryStorageType.YES:
      return 'Gewünscht'
    case BatteryStorageType.NO:
      return 'Nicht gewünscht'
    default:
      return ''
  }
}

const formatWallboxType = (type: WallboxType | null | undefined) => {
  switch (type) {
    case WallboxType.YES:
      return 'Gewünscht'
    case WallboxType.NO:
      return 'Nicht gewünscht'
    default:
      return ''
  }
}
const formatMonumentProtectionType = (type: MonumentProtectionType | null | undefined) => {
  switch (type) {
    case MonumentProtectionType.YES:
      return 'Ja'
    case MonumentProtectionType.NO:
      return 'Nein'
    default:
      return ''
  }
}

const formatEnergySource = (type: EnergySourceType | null | undefined): string => {
  switch (type) {
    case EnergySourceType.GAS:
      return 'Gas'
    case EnergySourceType.HEATING_OIL:
      return 'Heizöl'
    case EnergySourceType.WOOD:
      return 'Holz'
    case EnergySourceType.DISTRICT_HEATING:
      return 'Fernwärme'
    default:
      return ''
  }
}

const formatHeatingType = (type: HeatingType | null | undefined): string => {
  switch (type) {
    case HeatingType.RADIATOR:
      return 'Heizkörper'
    case HeatingType.UNDERFLOOR_HEATING:
      return 'Fußbodenheizung'
    case HeatingType.COMBINATION:
      return 'Kombination'
    case HeatingType.OTHER:
      return 'Sonstiges'
    default:
      return ''
  }
}

const formatInsulationType = (types: InsulationType[] | null | undefined) => {
  const germanTypes = types?.map((type) => {
    switch (type) {
      case InsulationType.FACADE_INSULATION:
        return 'Fassadendämmung'
      case InsulationType.ROOF_INSULATION:
        return 'Dachdämmung'
      case InsulationType.DOORS_AND_WINDOWS:
        return 'Türen und Fenster'
      case InsulationType.BASEMENT_INSULATION:
        return 'Kellerdämmung'
      case InsulationType.NO:
        return 'Keine'
      default:
        return ''
    }
  })
  return germanTypes?.join(', ')
}
const formatResidentialUsageType = (type: ResidentialUsageType | null | undefined) => {
  switch (type) {
    case ResidentialUsageType.YES:
      return 'Ja'
    case ResidentialUsageType.NO:
      return 'Nein'
    default:
      return ''
  }
}

const formatHouseholdSizeType = (type: HouseholdSizeType | null | undefined) => {
  switch (type) {
    case HouseholdSizeType.ONE_OR_TWO:
      return '1-2 Personen'
    case HouseholdSizeType.THREE:
      return '3 Personen'
    case HouseholdSizeType.FOUR:
      return '5 Personen'
    case HouseholdSizeType.FIVE_PLUS:
      return '5+ Personen'
    default:
      return ''
  }
}

const formatRoofType = (type: RoofType | null | undefined) => {
  switch (type) {
    case RoofType.FLAT_ROOF:
      return 'Flachdach'
    case RoofType.STEEP_ROOF_GABLE:
      return 'Satteldach'
    case RoofType.STEEP_ROOF_HIP:
      return 'Walmdach'
    case RoofType.NOT_SURE:
      return 'Sonstiges/Weiß ich nicht'
  }
}

const formatWindowAmount = (amount: WindowAmount | null | undefined) => {
  switch (amount) {
    case WindowAmount.ONE:
      return '1'
    case WindowAmount.TWO_TO_FOUR:
      return '2-4'
    case WindowAmount.MORE_THAN_FOUR:
      return 'Mehr als 4'
    case WindowAmount.NOT_SURE:
      return 'Sonstiges/Weiß ich nicht'
  }
}

const formatWindowPaneCount = (count: WindowPaneCount | null | undefined) => {
  switch (count) {
    case WindowPaneCount.SINGLE:
      return 'Einfach-Verglasung'
    case WindowPaneCount.DOUBLE:
      return 'Zweifach-Verglasung'
    case WindowPaneCount.TRIPLE:
      return 'Dreifach-Verglasung'
    case WindowPaneCount.NOT_SURE:
      return 'Sonstiges/Weiß ich nicht'
  }
}

const formatWindowDisposal = (count: WindowDisposal | null | undefined) => {
  switch (count) {
    case WindowDisposal.YES:
      return 'Ja'
    case WindowDisposal.NO:
      return 'Nein'
    case WindowDisposal.NOT_SURE:
      return 'Sonstiges/Weiß ich nicht'
  }
}

const formatRequesterType = (type: RequesterType | null | undefined) => {
  switch (type) {
    case RequesterType.PRIVATE:
      return 'Privat'
    case RequesterType.COMMERCIAL:
      return 'Kommerziell'
    case RequesterType.NOT_SURE:
      return 'Sonstiges/Weiß ich nicht'
  }
}

const getRichLeadData = (
  gdprDeleted: boolean,
  leadType: string | null,
  requester: Requester,
  realEstate: RealEstate,
  leadData: LeadData,
): RichLeadData => {
  return {
    name: gdprDeleted
      ? requester.salutation
      : `${getSalutation(requester.salutation)} ${requester.firstName} ${requester.lastName}`,
    phone: requester.phoneNumber,
    phoneAlternative: requester.secondaryPhoneNumber,
    email: requester.email,
    availability: leadData.ownerAvailability == null ? '' : leadData.ownerAvailability,
    contactAvailability: leadData.contactAvailability == null ? '' : leadData.contactAvailability,
    isOwner: getOwnerType(leadData.ownerType),
    userIntent: leadType == LeadType.COMMERCIAL ? formatUserIntent(leadData.userIntent as UserIntent) : null,
    objectDetails: {
      address:
        gdprDeleted || leadType === LeadType.BUYER ? (
          `${realEstate.address.postcode} ${realEstate.address.city}`
        ) : (
          <Fragment>
            {realEstate.address.street == null || realEstate.address.houseNumber == null ? (
              ''
            ) : (
              <Fragment>
                {realEstate.address.street} {realEstate.address.houseNumber}
                {', '}
              </Fragment>
            )}
            {realEstate.address.postcode} {realEstate.address.city}
          </Fragment>
        ),
      data: [
        {
          label: 'Objektinformationen',
          rows: getRichLeadObjectInformation(leadType, requester, realEstate),
        },
        {
          label: 'Ausstattung',
          rows: removeEmpty([
            {
              name: 'Balkon/Terrasse',
              value: getBalconyPatio(realEstate.balconyPatio),
            },
            {
              name: 'Ausstattung Bad',
              value: realEstate.bathroomInformation,
            },
            {
              name: 'Keller',
              value: realEstate.basement == null ? null : getBasement(realEstate.basement),
            },
            {
              name: 'Stellplatz',
              value: realEstate.parkingSpace,
            },
            {
              name: 'Aufzug',
              value:
                realEstate.facilities.length == 0
                  ? null
                  : booleanToString(realEstate.facilities.includes(Facility.ELEVATOR)),
            },
            {
              name: 'Einbauküche',
              value: has(realEstate.builtInKitchen),
            },
            { name: 'Fenster', value: realEstate.windows },
            { name: 'Heizung', value: realEstate.heating },
            { name: 'Dach', value: realEstate.roof },
          ]),
        },
      ],
      extendedData: removeEmpty([
        {
          name: 'Besondere Ausstattungsmerkmale',
          value: realEstate.furtherRealEstateInformation,
        },
        { name: 'Sonstiges', value: leadData.others },
      ]),
    },
  }
}

const leadDetailsBuilderService = (
  type: string | null,
  gdprDeleted: boolean,
  requester: Requester,
  realEstate: RealEstate,
  leadData: LeadData,
): BasicLeadData | RichLeadData => {
  switch (type) {
    case LeadType.BASIC:
      return getBasicLeadData(gdprDeleted, type, requester, realEstate)
    case LeadType.SOLAR:
      return getSolarLeadData(gdprDeleted, requester, leadData, realEstate)
    case LeadType.ENERGY_ADVISOR:
      return getEnergyAdvisorLeadData(gdprDeleted, requester, leadData, realEstate)
    case LeadType.HEATING:
      return getHeatingLeadData(gdprDeleted, requester, leadData, realEstate)
    case LeadType.WALL_WINDOW:
      return getWallWindowLeadData(gdprDeleted, requester, leadData, realEstate)
    case LeadType.ROOF_WINDOW:
      return getRoofWindowLeadData(gdprDeleted, requester, leadData, realEstate)
    default: {
      return getRichLeadData(gdprDeleted, type, requester, realEstate, leadData)
    }
  }
}

export default leadDetailsBuilderService
