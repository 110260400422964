import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './reducer'
import { reportingMiddleware } from '../api/reporting'
import { isDevelopmentMode, isFunctionalTest } from '../config'

export const store = configureStore({
  reducer: rootReducer,
  devTools: isDevelopmentMode() || isFunctionalTest(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(reportingMiddleware),
})

export type AppDispatch = typeof store.dispatch
