import React, { FC } from 'react'
import Media from 'react-media'
import { PALM_MAX, LAP_MIN, LAP_MAX, DESK_MIN } from '../../globals'

const width = {
  palmMax: PALM_MAX,
  lapMin: LAP_MIN,
  lapMax: LAP_MAX,
  deskMin: DESK_MIN,
}

type MediaComponentProps = {
  render?: () => React.ReactNode
  children?: React.ReactNode
}

// Desktop, tablet and mobile setup
export const Desktop: FC<MediaComponentProps> = (props) => (
  <Media query={{ minWidth: width.deskMin }} render={props.render}>
    {props.children}
  </Media>
)

export const Tablet: FC<MediaComponentProps> = (props) => (
  <Media query={{ minWidth: width.lapMin, maxWidth: width.lapMax }} render={props.render}>
    {props.children}
  </Media>
)

export const Mobile: FC<MediaComponentProps> = (props) => (
  <Media query={{ maxWidth: width.palmMax }} render={props.render}>
    {props.children}
  </Media>
)

// Combined setups
export const TabletAndMobile: FC<MediaComponentProps> = (props) => (
  <Media query={{ maxWidth: width.lapMax }} render={props.render}>
    {props.children}
  </Media>
)

export const DesktopAndTablet: FC<MediaComponentProps> = (props) => (
  <Media query={{ minWidth: width.lapMin }} render={props.render}>
    {props.children}
  </Media>
)
