import React, { useEffect } from 'react'
import Leads from '../leadsView/Leads'
import NotRLECustomer from '../notRLECustomerView/NotRLECustomer'
import { doRecaptcha } from '../../google/recaptcha'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import Loading from '../loading/Loading'
import { loadLeads } from '../../store/actions/leads'

const Home = () => {
  const { token: accessToken, isRleCustomer } = useAppSelector((state) => state.auth)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (accessToken) {
      const date = new Date()
      dispatch(loadLeads({ year: date.getFullYear(), month: date.getMonth() + 1 }))
    }
    doRecaptcha()
  }, [dispatch, accessToken])

  const renderContent = () => {
    if (isRleCustomer === null) {
      return <Loading />
    }
    return isRleCustomer ? <Leads /> : <NotRLECustomer />
  }

  return <div>{renderContent()}</div>
}

export default Home
