import React, { useState } from 'react'
import { Font, RadioButton, TextArea, TextInput } from 'is24-corecss'
import LeadStatus from '../../leadStatus/LeadStatus'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import {
  cancelLead,
  type CancelLeadRequest,
  changeLeadStatus,
  setLeadAsConverted,
  SetLeadAsConvertedRequest,
} from '../../../store/actions/leads'
import { leadStatus } from '../../../enums/LeadStatus'
import CancellationForm, { CANCELLATION_REASON_LABEL, CANCELLATION_REASON } from './CancellationForm'
import './CancellationStateUI.css'
import { trackEvent } from '../../../api/reporting'
import { type Lead } from '../../../types/lead'
import IconWithToolTip from '../../iconWithTooltip/IconWithToolTip'
import Badge from '../../badge/Badge'

export enum Status {
  SOLD = 'SOLD',
  MANDATE = 'MANDATE',
  ON_SITE_APPOINTMENT = 'ON_SITE_APPOINTMENT',
  PHONE_CONTACT = 'PHONE_CONTACT',
}

export const STATUS_LABEL = {
  [Status.SOLD]: 'Verkauft',
  [Status.MANDATE]: 'Auftrag',
  [Status.ON_SITE_APPOINTMENT]: 'Termin vor Ort',
  [Status.PHONE_CONTACT]: 'Telefonischer Kontakt',
}

export interface CancellationStateUIProps {
  lead: Lead
}

const CancellationStateUI = ({ lead }: CancellationStateUIProps) => {
  const {
    isCancellationSubmissionInProgress,
    cancellationErrorMessage,
    showChangeStatusErrorMessage,
    isChangingLeadStatus,
    isSettingConversionInProgress,
  } = useAppSelector((state) => state.leads)
  const [cancellationFormOpened, setCancellationFormOpened] = useState<boolean>(false)
  const [convertConfirmationOpened, setConvertedConfirmationOpened] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const onCancellationLinkClick = () => {
    if (!cancellationFormOpened) {
      trackEvent('lead-details', 'click-cancellation-link')
      setCancellationFormOpened(true)
    }
  }

  const onSetConvertedLinkClick = () => {
    if (!convertConfirmationOpened) {
      trackEvent('lead-details', 'click-set-as-converted-button')
      setConvertedConfirmationOpened(true)
    }
  }

  const submitSetConvertedLead = () => {
    trackEvent('lead-details', 'click-set-as-converted_submit-button')
    dispatch(
      setLeadAsConverted({
        assignmentId: lead.assignmentId,
        leadType: lead.leadType,
      } as SetLeadAsConvertedRequest),
    )
    setConvertedConfirmationOpened(false)
  }
  const cancelSetConvertedLead = () => {
    trackEvent('lead-details', 'click-set-as-converted_cancel-link')
    setConvertedConfirmationOpened(false)
  }

  const submitCancellation = (cancellationReason: string, cancellationReasonDetail?: string) => {
    trackEvent('lead-details', 'click-cancellation-button')
    dispatch(
      cancelLead({
        cancellationReason,
        cancellationReasonDetail,
        assignmentId: lead.assignmentId,
        leadType: lead.leadType,
      } as CancelLeadRequest),
    )
  }

  const radioButtonChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (lead.assignmentId) {
      dispatch(
        changeLeadStatus({
          assignmentId: lead.assignmentId,
          newStatus: e.target.value,
        }),
      )
    }
  }

  const cancelComplaint = () => {
    trackEvent('lead-details', 'click_cancel-link')
    setCancellationFormOpened(false)
  }

  const renderStatusRadioButton = (status: Status) => {
    return (
      <div className={'grid-item'}>
        <RadioButton
          id={`requestStatus_${status}`}
          label={STATUS_LABEL[status]}
          value={status}
          name={'requestStatus'}
          disabled={isChangingLeadStatus}
          defaultChecked={lead.status === status}
          data-testid={`lead-status-radio-${status}`}
        />
      </div>
    )
  }

  const isLeadInCancellationState = (): boolean => {
    return (
      lead.status === leadStatus.CANCELLATION_APPROVED ||
      lead.status === leadStatus.CANCELLATION_IN_PROGRESS ||
      lead.status === leadStatus.CANCELLATION_REJECTED
    )
  }

  return (
    <>
      <div className='grid grid-flex'>
        {lead.status && (
          <div className='margin-right-l margin-bottom-m'>
            <LeadStatus value={lead.status} isLong />
          </div>
        )}
        {lead.leadType != 'BUYER' && lead.cancellable && !cancellationFormOpened && (
          <a className='grid-item' onClick={onCancellationLinkClick} data-testid='open-cancellation-dialog-link'>
            Anfrage reklamieren
          </a>
        )}
        {!lead.cancellable &&
          lead.payPerConverted &&
          !lead.payPerConvertedIsInvoiced &&
          !convertConfirmationOpened &&
          !isSettingConversionInProgress && (
            <div className='grid grid-flex items-center'>
              <a onClick={onSetConvertedLinkClick} data-testid='open-set-converted-link' className={'margin-right-m'}>
                Erfolgreich konvertiert
              </a>{' '}
              <div className={'margin-top-xs'}>
                <IconWithToolTip
                  icon={'is24-icon-question-mark'}
                  tooltip={'Dadurch wird eine Rechnung erstellt. Dies kann nicht rückgängig gemacht werden kann.'}
                />
              </div>
            </div>
          )}
        {convertConfirmationOpened && (
          <div className='grid grid-flex margin-top-s'>
            <div className='grid-item one-whole margin-bottom-l'>
              <div className='grid-item one-whole margin-bottom-l'>
                <p className={'font-highlight'}>Bist du sicher?</p>
                <p>
                  Durch die Bestätigung wird eine Rechnung erstellt. Dies kann nicht rückgängig gemacht werden kann.
                </p>
              </div>
              <div className='grid-item one-third'>
                <a
                  onClick={submitSetConvertedLead}
                  className={'button-primary'}
                  data-testid='set-converted-submit-button'
                >
                  Bestätigen
                </a>
                <a
                  className={'margin margin-left-l'}
                  onClick={cancelSetConvertedLead}
                  data-testid='set-converted-cancel-link'
                >
                  Zurück
                </a>
              </div>
            </div>
          </div>
        )}
        {!lead.cancellable &&
          lead.payPerConverted &&
          lead.payPerConvertedIsInvoiced &&
          !isSettingConversionInProgress && (
            <div className='grid grid-flex items-center margin-top-s'>
              <span className={''}>
                <Badge className='margin-right-m badge--teal' testid='converted-lead-status-badge'>
                  Konvertiert
                </Badge>
              </span>
              <div className={'margin-top-xs'}>
                <IconWithToolTip icon={'is24-icon-question-mark'} tooltip={'Lead konvertiert'} />
              </div>
            </div>
          )}
        {cancellationFormOpened && (
          <a
            className='grid-item desk-hide lap-hide'
            onClick={cancelComplaint}
            data-testid='cancel-complaint-link-palm'
          >
            Reklamation abbrechen
          </a>
        )}
      </div>
      {cancellationFormOpened && (
        <div className='grid grid-flex margin-top-s'>
          <div className='grid-item one-whole margin-bottom-l'>
            <CancellationForm
              onSubmit={submitCancellation}
              disabled={isCancellationSubmissionInProgress}
              onCancel={cancelComplaint}
              cancellationErrorMessage={cancellationErrorMessage}
            />
          </div>
        </div>
      )}

      {lead.leadType != 'BUYER' && !isLeadInCancellationState() && !cancellationFormOpened && (
        <div>
          <div className={'section-header'}>Status der Anfrage</div>
          <div className={'grid grid-flex'} onChange={radioButtonChangeHandler}>
            {renderStatusRadioButton(Status.PHONE_CONTACT)}
            {renderStatusRadioButton(Status.ON_SITE_APPOINTMENT)}
            {renderStatusRadioButton(Status.MANDATE)}
            {renderStatusRadioButton(Status.SOLD)}
          </div>
          {showChangeStatusErrorMessage && (
            <Font className='error-font margin-left-m' color='error'>
              Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut
            </Font>
          )}
        </div>
      )}

      {lead.cancellationReason && (
        <div className='margin-top-s'>
          <Font weight='bold'>Reklamationsgrund</Font>
          {lead.cancellationReason == CANCELLATION_REASON.OTHER && (
            <div data-testid={'cancellation-details-disabled'} className='grid-item one-whole'>
              <TextArea
                id=''
                label=''
                value={lead.cancellationReasonDetail!}
                disabled
                placeholder=''
                className='cancellation-details-disabled'
              />
            </div>
          )}
          {lead.cancellationReason != CANCELLATION_REASON.OTHER && (
            <div data-testid={'cancellation-reason-text-disabled'} className='one-fourth'>
              <TextInput
                id=''
                placeholder=''
                label=''
                disabled
                value={CANCELLATION_REASON_LABEL[lead.cancellationReason as CANCELLATION_REASON]}
              />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CancellationStateUI
