import config from '../config'
import authorizeMe from '../oauth/oauth2'

export const oauthProtectedRequest = async (
  endpoint: string,
  options: RequestInit,
  accessToken: string,
): Promise<Response | null> => {
  try {
    const authHeader = { Authorization: 'BEARER ' + accessToken }
    const url = config.routing.rleApiURL + endpoint
    const response = await fetch(url, {
      ...options,
      headers: { ...authHeader, ...options.headers } as HeadersInit,
    })

    if (response.status === 401) {
      authorizeMe()
    }

    return response
  } catch (err) {
    console.error(err)
    const errMsg = `Internal Server Error: ${err}`
    return new Response(JSON.stringify({ message: errMsg }), {
      status: 500,
      headers: { 'Content-Type': 'application/json' },
    })
  }
}
