import 'url-search-params-polyfill'

export const FEATURE_NAMES = {
  VALUATION_PDF: 'HOS-2136',
}

const DEFAULT_FEATURE_SETTINGS = {
  [FEATURE_NAMES.VALUATION_PDF]: true,
}

const getFeatureToggleValue = (featureName, defaultFeatureSettings) => {
  const urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has(featureName)) {
    if (urlParams.get(featureName) === 'true') {
      return true
    }
    if (urlParams.get(featureName) === 'false') {
      return false
    }
  }

  if (localStorage.getItem('features')) {
    const storedFeatures = JSON.parse(localStorage.getItem('features'))
    if (storedFeatures.includes(featureName)) {
      return true
    }
  }

  return defaultFeatureSettings[featureName]
}

const featureTogglesConfiguration = (
  featureNames = FEATURE_NAMES,
  defaultFeatureSettings = DEFAULT_FEATURE_SETTINGS,
) => {
  const activeFeatures = Object.values(featureNames).filter((featureName) =>
    getFeatureToggleValue(featureName, defaultFeatureSettings),
  )
  localStorage.setItem('features', JSON.stringify(activeFeatures))
  return activeFeatures
}

export default featureTogglesConfiguration
