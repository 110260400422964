import { type Middleware } from '@reduxjs/toolkit'
import config from './../config'
import { Status } from '../components/leadDetailView/cancellationUI/CancellationStateUI'
import { cancelLead, changeLeadStatus } from '../store/actions/leads'

const TRACKING_PREFIX = 'is24.de.scoutmanager.rle.'

export const trackPageView = (pageName: string) => {
  const payload = {
    pag_pagetitle: TRACKING_PREFIX + pageName,
  }

  report(payload, { reportType: 'UTAG_VIEW' })
}

export const trackEvent = (pageName?: string, label?: string, value?: string) => {
  const payload = {
    pag_pagetitle: TRACKING_PREFIX + pageName,
    evt_ga_category: 'residential',
    evt_ga_action: 'rle-cockpit',
    evt_ga_label: label,
    ga_cd_lead_engine_input_value: value,
  }

  report(payload)
}

function report(payload: unknown, reportType?: unknown) {
  if (config.reporting.useTealium) {
    reportToTealium(payload, reportType)
  } else {
    console.log(JSON.stringify(payload))
  }
}

async function sleep(millis: number) {
  return await new Promise((resolve) => setTimeout(resolve, millis))
}

const reportToTealium = async (payload: unknown, reportType: unknown) => {
  if (!isTealiumAvailableYet()) {
    await sleep(2000)
  }
  if (isTealiumAvailableYet()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.IS24.TEALIUM.tracking.report(payload, reportType)
  } else {
    console.error('Could not find Tealium within 2 seconds')
  }
}

const isTealiumAvailableYet = () =>
  window.IS24 &&
  window.IS24.TEALIUM &&
  window.IS24.TEALIUM.tracking &&
  window.IS24.TEALIUM.tracking.report &&
  window.utag

export const STATUS_REPORTING_VALUE: Record<string, string> = {
  [Status.SOLD]: 'SOLD',
  [Status.MANDATE]: 'MANDATE',
  [Status.ON_SITE_APPOINTMENT]: 'VISIT',
  [Status.PHONE_CONTACT]: 'CONTACT',
}

export const reportingMiddleware: Middleware = () => (next) => (action) => {
  switch (action.type) {
    case cancelLead.rejected.type:
      trackEvent('lead-details', 'static-error-message')
      break
    case changeLeadStatus.pending.type:
      trackEvent('lead-details', 'lead-status_submit', STATUS_REPORTING_VALUE[action.status])
      break
    default:
      break
  }

  return next(action)
}
