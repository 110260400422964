import React from 'react'
import PropTypes from 'prop-types'
import './Badge.css'
import { Indicator } from 'is24-corecss'

const Badge = ({ className, children, testid }) => {
  return (
    <div className='badge' data-testid={testid}>
      <span className={className}>
        <Indicator appearance='dot' />
        {children}
      </span>
    </div>
  )
}

Badge.propTypes = {
  className: PropTypes.string,
}

export default Badge
