import React, { useCallback, useEffect } from 'react'
import { ModalBackdrop, ModalContainer } from 'is24-corecss'
import { useFeatures } from '@paralleldrive/react-feature-toggles'

import './LeadDetailView.css'
import { trackEvent, trackPageView } from '../../api/reporting'
import { FEATURE_NAMES } from '../../feature/features'
import { ModalHeader } from './ModalHeader'
import { ModalContent } from './ModalContent'
import { fetchValuationPdfUrl } from '../../store/actions/valuationPdf'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setIsModalVisible } from '../../store/slices/leadDetailViewUiSlice'
import { type Lead } from '../../types/lead'

const PAGE_NAME = 'lead-details'

const LeadDetailView = () => {
  const features = useFeatures()
  const lead = useAppSelector((state) =>
    typeof state.leads.indexOfClickedLead === 'number'
      ? state.leads.leads[state.leads.indexOfClickedLead]
      : ({} as Lead),
  )
  const visible = useAppSelector((state) => state.leadDetailViewUi.isModalVisible)

  const dispatch = useAppDispatch()

  const isValuationPdfFeatureEnabled = useCallback(
    (): boolean => features.includes(FEATURE_NAMES.VALUATION_PDF),
    [features],
  )
  const fetchValuationPdfUrlCb = useCallback(() => {
    if (visible) {
      trackPageView(PAGE_NAME)

      if (isValuationPdfFeatureEnabled() && lead.leadType === 'RICH' && typeof lead.assignmentId !== 'undefined') {
        dispatch(fetchValuationPdfUrl({ assignmentId: lead.assignmentId as number, httpRequestMethod: 'GET' }))
      }
    }
  }, [dispatch, visible, isValuationPdfFeatureEnabled, lead.assignmentId, lead.leadType])

  useEffect(() => {
    fetchValuationPdfUrlCb()
  }, [visible, fetchValuationPdfUrlCb])

  const closeModal = (): void => {
    dispatch(setIsModalVisible(false))
    trackEvent(PAGE_NAME, 'close-lead-details')
  }

  return (
    <>
      <ModalBackdrop visible={visible} onClose={closeModal}>
        <ModalContainer
          visible={visible}
          modalTitle={<ModalHeader lead={lead} />}
          onClose={closeModal}
          containerClassName='newModalWrapper'
          ariaLabelCloseButton='is24-icon-closing'
        >
          <ModalContent />
        </ModalContainer>
      </ModalBackdrop>
    </>
  )
}

export default LeadDetailView
