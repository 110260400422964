import { combineReducers } from 'redux'
import authReducer, { initialAuthState } from './slices/authSlice'
import leadsReducer, { initialLeadsState } from './slices/leadsSlice'
import valuationPdfReducer, { initialValuationPdfState } from './slices/valuationPdfSlice'
import leadDetailViewUiReducer, { initialLeadDetailViewUiState } from './slices/leadDetailViewUiSlice'

const rootReducer = combineReducers({
  leads: leadsReducer,
  auth: authReducer,
  valuationPdf: valuationPdfReducer,
  leadDetailViewUi: leadDetailViewUiReducer,
})

export default rootReducer

export type ReduxState = ReturnType<typeof rootReducer>

export const initialReduxDefaultState: ReduxState = {
  leads: initialLeadsState,
  auth: initialAuthState,
  valuationPdf: initialValuationPdfState,
  leadDetailViewUi: initialLeadDetailViewUiState,
}
