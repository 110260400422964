import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import config, { isDevelopmentMode, isFunctionalTest } from './config'
import { store } from './store/store'
import App from './App'
import './index.css'

if (config.reporting.useTealium) {
  const script = document.createElement('script')
  script.src = config.reporting.url
  script.async = true
  document.body.appendChild(script)
}

if (isDevelopmentMode() || isFunctionalTest()) {
  console.log(
    `Running in ${isDevelopmentMode() ? 'development' : 'functional test'} mode!`,
    'env:',
    process.env.NODE_ENV,
    'app_profile:',
    process.env.REACT_APP_PROFILE,
  )
}

const reCaptchaScript = document.createElement('script')
reCaptchaScript.src = `https://www.google.com/recaptcha/api.js?onload=onloadRecaptcha&render=${config.reCaptchaSiteKey}`
document.body.appendChild(reCaptchaScript)

const rootElement = document.getElementById('root') ?? document.createElement('div')
createRoot(rootElement).render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
)
