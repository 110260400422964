import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UserAuth {
  token?: string
  isRleCustomer: boolean | null
}

export const initialAuthState: UserAuth = {
  isRleCustomer: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login: (state: UserAuth, action: PayloadAction<string>) => {
      state.token = action.payload
    },
    logout: (state: UserAuth) => {
      state.token = undefined
      state.isRleCustomer = null
    },
    setIsRleCustomer: (state: UserAuth, action: PayloadAction<boolean>) => {
      state.isRleCustomer = action.payload
    },
  },
})

export const { login, logout, setIsRleCustomer } = authSlice.actions

export default authSlice.reducer
