import { createAsyncThunk } from '@reduxjs/toolkit'
import { isDevelopmentMode } from '../../config'
import mockedLeads from '../../localDevelopment/leads/leads.json'
import { oauthProtectedRequest } from '../../api/fetch'
import { sleep } from '../../helpers/shared'
import { setIsRleCustomer } from '../slices/authSlice'
import { type Lead } from '../../types/lead'
import { type ReduxState } from '../reducer'

interface LoadLeadsRequest {
  year: number | string
  month: number | string
}

export const loadLeads = createAsyncThunk<Lead[], LoadLeadsRequest>(
  'leads/loadLeads',
  async (request: LoadLeadsRequest, thunkAPI) => {
    if (isDevelopmentMode()) {
      return mockedLeads
    }
    const { year, month } = request
    const accessToken = (thunkAPI.getState() as ReduxState).auth.token as string
    const response = await oauthProtectedRequest(
      `/api/customercockpit/leads?year=${year}&month=${month}`,
      {},
      accessToken,
    )

    if (response?.status === 204) {
      thunkAPI.dispatch(setIsRleCustomer(false))
      return []
    } else if (response?.status === 200) {
      thunkAPI.dispatch(setIsRleCustomer(true))
    }

    if (response?.ok) {
      const data = await response.json()
      return data
    }

    const errorText = await response?.text()
    return thunkAPI.rejectWithValue(errorText)
  },
)

export const changeLeadStatus = createAsyncThunk<
  { assignmentId: number; newStatus: string },
  { assignmentId: number; newStatus: string }
>('leads/changeLeadStatus', async (request, thunkAPI) => {
  const { assignmentId, newStatus } = request

  if (isDevelopmentMode()) {
    console.log(`Change status requested: ${assignmentId} - ${newStatus}`)
    await sleep(1000)
    return { assignmentId, newStatus }
  }

  const url = `/api/customercockpit/status`
  const reduxState = thunkAPI.getState() as ReduxState
  const accessToken = reduxState.auth.token as string
  const data = {
    id: assignmentId,
    status: newStatus,
  }
  const response = await oauthProtectedRequest(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
    accessToken,
  )

  if (response?.ok) {
    return { assignmentId, newStatus }
  }

  const errorText = await response?.text()
  return thunkAPI.rejectWithValue(errorText)
})

export interface CancelLeadRequest {
  assignmentId: number
  leadType: string
  cancellationReason: string
  cancellationReasonDetail: string
}

export const cancelLead = createAsyncThunk<
  Lead,
  CancelLeadRequest,
  {
    rejectValue: string
  }
>('leads/submitCancellation', async (request, thunkAPI) => {
  const { assignmentId, leadType, cancellationReason, cancellationReasonDetail } = request

  if (isDevelopmentMode()) {
    console.log(`Cancellation requested: ${cancellationReason} - ${cancellationReasonDetail}`)
    const leadtoCancel = mockedLeads.find((lead) => lead.assignmentId === assignmentId)
    if (leadtoCancel) {
      await sleep(500)
      return leadtoCancel
    }
  }

  const url = `/api/customercockpit/v2/cancel`
  const accessToken = (thunkAPI.getState() as ReduxState).auth.token as string
  const requestBody = {
    assignmentId,
    leadType,
    cancellationReason,
    comment: cancellationReasonDetail,
  }
  const response = await oauthProtectedRequest(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    },
    accessToken,
  )

  if (response?.ok) {
    const data = await response.json()
    return data
  }

  const errorText = await response?.text()
  return thunkAPI.rejectWithValue(errorText ?? 'Unknown error')
})

export interface SetLeadAsConvertedRequest {
  assignmentId: number
  leadType: string
}

export const setLeadAsConverted = createAsyncThunk<
  Lead,
  SetLeadAsConvertedRequest,
  {
    rejectValue: string
  }
>('leads/setLeadAsConverted', async (request, thunkAPI) => {
  const { assignmentId, leadType } = request

  if (isDevelopmentMode()) {
    console.log(`SetAsConverted requested:`)
    const leadtoConvert = mockedLeads.find((lead) => lead.assignmentId === assignmentId)
    if (leadtoConvert) {
      await sleep(500)
      return { ...leadtoConvert, payPerConvertedIsInvoiced: true }
    }
  }
  const url = `/api/customercockpit/set-converted`
  const accessToken = (thunkAPI.getState() as ReduxState).auth.token as string
  const requestBody = {
    assignmentId,
    leadType,
    eventDate: Date.now(),
  }
  console.log('leads/setLeadAsConverted started!!')
  const response = await oauthProtectedRequest(
    url,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    },
    accessToken,
  )

  if (response?.ok) {
    return await response.json()
  } else {
    const errorText = await response?.text()
    return thunkAPI.rejectWithValue(errorText ?? 'Unknown error')
  }
})
