import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { fetchValuationPdfUrl, ValuationPdfErrorType } from '../actions/valuationPdf'

export interface ValuationPdf {
  valuationPdfUrl: string
  valuationPdfErrorType: ValuationPdfErrorType | null
  valuationPdfInProgress: boolean
}

export const initialValuationPdfState: ValuationPdf = {
  valuationPdfUrl: '',
  valuationPdfErrorType: null,
  valuationPdfInProgress: false,
}

const valuationPdfSlice = createSlice({
  name: 'valuationPdf',
  initialState: initialValuationPdfState,
  reducers: {
    clearUrl: (state: ValuationPdf) => {
      state.valuationPdfUrl = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchValuationPdfUrl.pending, (state: ValuationPdf) => {
      state.valuationPdfUrl = ''
      state.valuationPdfInProgress = true
    })
    builder.addCase(fetchValuationPdfUrl.fulfilled, (state: ValuationPdf, action: PayloadAction<string>) => {
      state.valuationPdfInProgress = false
      state.valuationPdfUrl = action.payload
      state.valuationPdfErrorType = null
    })
    builder.addCase(
      fetchValuationPdfUrl.rejected,
      (state: ValuationPdf, action: PayloadAction<ValuationPdfErrorType | undefined>) => {
        state.valuationPdfInProgress = false
        state.valuationPdfErrorType = action.payload ?? null
      },
    )
  },
})

export default valuationPdfSlice.reducer
