export default {
  routing: {
    rleApiURL: 'http://localhost:80/rle-api',
  },
  header: {
    baseUrl: 'https://http://localhost:80/header',
  },
  footer: {
    baseUrl: 'https://localhost:80/footer',
  },
  oauth: {
    url: 'http://localhost:80/oauth/authorize',
    client: 'realtor-lead-engine-customer-cockpit',
    redirect: 'http://localhost:80/',
  },
  manager: {
    url: 'https://http://localhost:80/manager',
  },
  reporting: {
    useTealium: false,
  },
  reCaptchaSiteKey: 'key-test',
}
