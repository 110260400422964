import React, { useEffect, useState } from 'react'
import { Button, Font } from 'is24-corecss'
import './Leads.css'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import DropdownMonthPicker from '../dropdownMonthPicker/DropdownMonthPicker'
import FallbackMessageNoLeads from '../fallbackMessageNoLeads/FallbackMessageNoLeads'
import { trackEvent, trackPageView } from '../../api/reporting'
import Loading from '../loading/Loading'
import Pagination from './Pagination'
import config from './../../config'
import StatusCell from './tableCells/StatusCell'
import TypeCell from './tableCells/TypeCell'
import NameCell from './tableCells/NameCell'
import DateCell from './tableCells/DateCell'
import DetailCell from './tableCells/DetailCell'
import ValuationCell from './tableCells/ValuationCell'
import AddressCell from './tableCells/AddressCell'
import { leadStatus } from '../../enums/LeadStatus'
import CancellationNotification from '../cancellationNotification/CancellationNotification'
import { totalOfPages } from '../../helpers/lead'
import LeadRow from './leadRow/LeadRow'
import { type Lead } from '../../types/lead'
import EyeCatcher from '../eyeCatcher/EyeCatcher'
import LeadDetailView from '../leadDetailView/LeadDetailView'
import { setIndexOfClickedLead, setShowCancellationSuccessMessage } from '../../store/slices/leadsSlice'
import { setIsModalVisible } from '../../store/slices/leadDetailViewUiSlice'

const PAGE_NAME = 'lead-overview'
export const ITEMS_PER_PAGE = 10
const CANCELLATION_NOTIFICATION_TIMEOUT = 3000

const isCancelledLead = (lead: Lead): boolean => lead.status === leadStatus.CANCELLATION_APPROVED

const Leads = () => {
  trackPageView(PAGE_NAME)
  const { showCancellationSuccessMessage, leads, isLoadingLeads } = useAppSelector((state) => state.leads)
  const [isCancellationConfirmationNotificationOpen, setIsCancellationConfirmationNotificationOpen] =
    useState<boolean>(showCancellationSuccessMessage)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const dispatch = useAppDispatch()

  useEffect(() => {
    let timeout: NodeJS.Timeout

    setIsCancellationConfirmationNotificationOpen(showCancellationSuccessMessage)
    if (showCancellationSuccessMessage) {
      timeout = setTimeout(() => {
        dispatch(setShowCancellationSuccessMessage(false))
      }, CANCELLATION_NOTIFICATION_TIMEOUT)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [dispatch, showCancellationSuccessMessage])

  const onChangePage = (pageIndex: number): void => {
    setCurrentPage(pageIndex)
  }

  const onChangeMonthPicker = (): void => {
    setCurrentPage(1)
  }

  const rowClicked = (index: number): void => {
    dispatch(setIndexOfClickedLead(index))
    dispatch(setIsModalVisible(true))
    trackEvent(PAGE_NAME, 'click-lead-details')
  }

  const handleOnClickEmailChange = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault()
    window.location.assign(config.manager.url)
  }

  return (
    <div className='Leads'>
      <LeadDetailView />
      {isCancellationConfirmationNotificationOpen && (
        <CancellationNotification onClose={() => dispatch(setShowCancellationSuccessMessage(false))} />
      )}
      <div className='leadsHeader margin-bottom-l'>
        <Font className='title palm-hide padding-top-l desk-font-xxxl lap-font-xxxl align-center' weight='normal'>
          Meine Eigentümeranfragen
        </Font>
        <Font className='title lap-hide desk-hide padding-top-xl palm-font-xxl align-center' weight='normal'>
          Meine <br />
          Eigentümeranfragen
        </Font>
        <Font
          className='sub-title font-lightgray desk-hide lap-hide margin-top-m palm-font-m align-center'
          weight='normal'
        >
          Hier finden Sie Ihre aktuellen Anfragen
        </Font>
        <Font className='sub-title font-lightgray palm-hide desk-font-l lap-font-l align-center' weight='normal'>
          Hier finden Sie Ihre aktuellen Anfragen
        </Font>
      </div>
      <div className='grid-item margin-bottom-l'>
        <div className='leadsOptions grid grid-flex grid-align-center gutter-vertical-l gutter-horizontal-l palm-justify-space-between'>
          <div className='grid-item'>
            <EyeCatcher count={leads.length} />
          </div>
          <div className='grid-item desk-hide lap-hide align-right'>
            <Button
              className='padding-none padding-top-m'
              textStyle={true}
              id='emailChangeButtonMobile'
              onClick={handleOnClickEmailChange}
            >
              E-Mail ändern
            </Button>
          </div>
          <div className='grid-item palm-one-whole lap-one-third'>
            <DropdownMonthPicker onChange={onChangeMonthPicker} />
          </div>
          <div className='grid-item palm-hide align-right lap-order-one-up lap-one-whole fill-remaining'>
            <Button appearance='secondary' id='emailChangeButton' onClick={handleOnClickEmailChange} aria-label='Edit'>
              <span className='is24-icon-edit' />
              &nbsp;E-Mail für Anfragen ändern
            </Button>
          </div>
        </div>
      </div>
      <div className='filters' />
      <div className='tableHeader tableHeader--desktop palm-hide'>
        <NameCell>Name</NameCell>
        <DetailCell>Anfrage</DetailCell>
        <DateCell>Datum</DateCell>
        <TypeCell>Typ</TypeCell>
        <ValuationCell>Bewertung</ValuationCell>
        <AddressCell>Adresse</AddressCell>
        <StatusCell>Status</StatusCell>
      </div>
      <div className='grid grid-flex'>
        <div className='tableHeader tableHeader--tablet one-whole palm-hide'>
          <NameCell>Name</NameCell>
          <DetailCell>Lead</DetailCell>
          <DateCell>Datum</DateCell>
          <AddressCell>Ort</AddressCell>
          <StatusCell>Status</StatusCell>
        </div>
      </div>
      {isLoadingLeads ? (
        <Loading />
      ) : (
        <div>
          {leads.length === 0 ? <FallbackMessageNoLeads /> : ''}
          {leads &&
            leads.map((lead, index) => {
              const hideLead = currentPage !== Math.ceil((index + 1) / ITEMS_PER_PAGE)
              return (
                <LeadRow
                  key={index}
                  lead={lead}
                  index={index}
                  hideLead={hideLead}
                  fadeOut={isCancelledLead(lead)}
                  onClick={rowClicked}
                />
              )
            })}
        </div>
      )}
      {totalOfPages(leads, ITEMS_PER_PAGE) > 1 ? (
        <Pagination
          onClick={onChangePage}
          currentPage={currentPage}
          totalOfPages={totalOfPages(leads, ITEMS_PER_PAGE)}
        />
      ) : null}
    </div>
  )
}

export default Leads
