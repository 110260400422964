import { useEffect } from 'react'
import { isDevelopmentMode, isFunctionalTest } from '../config'
import { login, setIsRleCustomer } from '../store/slices/authSlice'
import authorizeMe from '../oauth/oauth2'
import { useAppDispatch } from './redux'

const useAccessToken = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fragment = window.location.hash.substring(1)
    const accessToken =
      isDevelopmentMode() || isFunctionalTest()
        ? 'LOCAL_ACCESS_TOKEN'
        : new URLSearchParams(fragment).get('access_token')

    if (accessToken) {
      dispatch(setIsRleCustomer(true))
      dispatch(login(accessToken))
    } else {
      authorizeMe()
    }
  }, [dispatch])
}

export default useAccessToken
