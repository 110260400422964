import React, { useEffect } from 'react'
import config from '../../config'

const Header = () => {
  useEffect(() => {
    const stage: 'pro' | 'box' = process.env.REACT_APP_PROFILE === 'pro' ? 'pro' : 'box'
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.src = `${config.header.baseUrl}/${stage}/is24-de-header-scoutmanager.js`
    document.body.appendChild(script)
  }, [])

  return (
    <div className='page-wrapper'>
      <div className='inject is24-de-header-scoutmanager'></div>
    </div>
  )
}

export default Header
