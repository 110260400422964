import React, { ReactElement } from 'react'
import { type Lead } from '../../types/lead'

type ModalHeaderProps = {
  lead: Lead
}

const ModalHeader = (props: ModalHeaderProps): ReactElement<ModalHeaderProps> => {
  const { lead } = props
  return (
    <div data-testid='modal-header' className='align-left font-bold'>
      {lead.cockpitName}
    </div>
  )
}

export { ModalHeader }
