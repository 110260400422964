export default {
  routing: {
    rleApiURL: 'http://somedomain.com',
  },
  header: {
    baseUrl: 'https://content.header.immobilienscout24.de/main',
  },
  footer: {
    baseUrl: 'https://www.sandbox-immobilienscout24.de',
  },
  oauth: {
    url: 'http://publicauth.sandbox-immobilienscout24.de/oauth/authorize',
    client: 'realtor-lead-engine-customer-cockpit',
    redirect: 'http://localhost:3000/',
  },
  manager: {
    url: 'https://manager.maklersuchmaschine.sandbox-immobilienscout24.de/',
  },
  reporting: {
    useTealium: false,
  },
  reCaptchaSiteKey: 'key-test',
}
