import React from 'react'
import { FeatureToggles } from '@paralleldrive/react-feature-toggles'
import featureTogglesConfiguration from './feature/features'
import useAccessToken from './hooks/useAccessToken'
import Header from './components/header/Header'
import Home from './components/home/Home'
import './App.css'

const App = () => {
  useAccessToken()

  return (
    <FeatureToggles features={featureTogglesConfiguration()}>
      <div className='viewport'>
        <Header />
        <div className='content-wrapper'>
          <Home />
        </div>
      </div>
    </FeatureToggles>
  )
}

export default App
