import React from 'react'
import './Pagination.css'

interface PaginationProps {
  currentPage: number
  totalOfPages: number
  onClick: (pageNumber: number) => void
}

const Pagination = (props: PaginationProps) => {
  const getLeftButtonActive = () => {
    return props.currentPage > 1
  }

  const getRightButtonActive = () => {
    return props.currentPage < props.totalOfPages
  }

  const getPaginatorElements = () => {
    const elements = []

    let pointsShown = false

    for (let pageIndex = 1; pageIndex <= props.totalOfPages; pageIndex++) {
      if (shouldRender(pageIndex)) {
        const currentPageClass = pageIndex === props.currentPage ? 'currentPage' : ''
        elements.push(
          <button
            data-testid={`PaginationButton_${pageIndex}`}
            onClick={() => {
              props.onClick(pageIndex)
            }}
            key={pageIndex}
            className={`link-text ${currentPageClass}`}
          >
            {pageIndex}
          </button>,
        )
        pointsShown = false
      } else if (!pointsShown) {
        elements.push(<span key={pageIndex}>…</span>)
        pointsShown = true
      }
    }
    return elements
  }

  const getOffset = () => {
    let offset
    const largeOffset = [4, 4]
    const middleOffset = [3, 3]
    const smallOffset = [2, 2]
    const specialOffset_2_1 = [2, 1]
    const specialOffset_1_2 = [1, 2]

    if (props.currentPage === 1 || props.currentPage === props.totalOfPages) {
      offset = largeOffset
    } else if (props.currentPage === 2 || props.currentPage === props.totalOfPages - 1) {
      offset = middleOffset
    } else if (props.currentPage === 4) {
      offset = specialOffset_2_1
    } else if (props.currentPage === props.totalOfPages - 3) {
      offset = specialOffset_1_2
    } else {
      offset = smallOffset
    }
    return offset
  }

  const shouldRender = (pageNumber: number): boolean => {
    const offset = getOffset()

    const offsetLeft = offset[0]
    const offsetRight = offset[1]

    return (
      pageNumber === 1 ||
      (pageNumber >= props.currentPage - offsetLeft && pageNumber <= props.currentPage + offsetRight) ||
      pageNumber === props.totalOfPages
    )
  }

  const buttonLeftTestId = getLeftButtonActive() ? 'PaginationLeftArrowActive' : 'PaginationLeftArrowInactive'

  const leftButton = (
    <button
      onClick={() => {
        if (props.currentPage > 1) {
          props.onClick(props.currentPage - 1)
        }
      }}
      className={'button-secondary'}
      data-testid={buttonLeftTestId}
    >
      &lt;
    </button>
  )

  const buttonRightTestId = getRightButtonActive() ? 'PaginationRightArrowActive' : 'PaginationRightArrowInactive'

  const rightButton = (
    <button
      onClick={() => {
        if (props.currentPage < props.totalOfPages) {
          props.onClick(props.currentPage + 1)
        }
      }}
      className={'button-secondary'}
      data-testid={buttonRightTestId}
    >
      &gt;
    </button>
  )

  const links = getPaginatorElements()

  return (
    <div className={'leadsPagination align-center'} data-testid='pagination'>
      {leftButton} {links} {rightButton}
    </div>
  )
}

export default Pagination
