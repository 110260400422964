import React, { Component } from 'react'
import { Button, Font } from 'is24-corecss'
import './NotRLECustomer.css'
import { trackEvent, trackPageView } from '../../api/reporting'

class NotRLECustomer extends Component {
  constructor(props: Record<string, never>) {
    super(props)
    trackPageView('lead-overview-non-customer')
  }

  goBack() {
    window.history.back()
  }

  handleOnClick(e: Event) {
    e.preventDefault()
    trackEvent('lead-overview-non-customer', 'click-email-non-rle-customer')
    window.location.assign('mailto: maklervergleich@immobilienscout24.de')
  }

  render() {
    return (
      <div className='main-container not-rle-customer'>
        <div className='darken'>
          <div className='popup'>
            <Font className='title palm-font-m lap-font-xl desk-font-xxl' weight='normal'>
              Sie erhalten noch keine Eigentümer-Leads
            </Font>
            <Font className='sub-title palm-font-s lap-font-s desk-font-s ' weight='normal'>
              Diese Ansicht steht nur Kund:innen zur Verfügung, die Eigentümer-Leads beziehen.
            </Font>

            <div className='grid grid-flex grid-justify-space-around  bullet-point-title lap-font-s'>
              Darum lohnen sich Eigentümer-Leads für Sie
            </div>
            <div className='bullet-contents'>
              <div className='grid grid-flex grid-justify-space-around margin-top-m palm-padding-l bullets'>
                <div className='grid-item bullet-font desk-one-half lap-three-fourth '>
                  <div className='grid-item bullet-font'>
                    <div className='grid grid-flex gutter-s'>
                      <div className='grid-item bullet-font'>
                        <i className='desk-font-xl palm-font-m is24-icon-positive-check'></i>
                      </div>
                      <div className='grid-item font-s bullet-font'>
                        Qualifizierte Anfragen von verkaufsinteressierten
                        <br />
                        Eigentümer:innen
                      </div>
                    </div>
                  </div>

                  <div className='grid-item'>
                    <div className='grid grid-flex gutter-s'>
                      <div className='grid-item'>
                        <i className='desk-font-xl palm-font-m is24-icon-positive-check'></i>
                      </div>
                      <div className='grid-item bullet-font'>Sie bestimmen die Abnahmeregion und -menge</div>
                    </div>
                  </div>
                  <div className='grid-item'>
                    <div className='grid grid-flex gutter-s'>
                      <div className='grid-item bullet-font'>
                        <i className='desk-font-xl palm-font-m is24-icon-positive-check'></i>
                      </div>
                      <div className='grid-item bullet-font'>Übersichtliches Cockpit hier im ScoutManager</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='contact margin-top-l'>
              <h4 className='content-title palm-font-m desk-font-m'>
                Möchten Sie auch gerne Eigentümeranfragen erhalten?
              </h4>
              <Font className='contact-us palm-font-s' weight='light'>
                Kontaktieren Sie uns:
              </Font>
              <Font className='team-name no-top-bottom-margin palm-font-xs desk-font-xs' weight='semibold'>
                Team Maklervergleich
              </Font>
              <Font className='phone-number no-top-bottom-margin palm-font-xs desk-font-xs' weight='light'>
                +49 30 24301-1530
              </Font>
              <Button
                textStyle={true}
                onClick={this.handleOnClick}
                className='info palm-font-xs desk-font-xs'
                id='email-address'
                data-testid='email-address'
              >
                maklervergleich@immobilienscout24.de
              </Button>
            </div>
            <Button onClick={this.goBack} textStyle={true} className='back-button' size='small'>
              Zurück
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default NotRLECustomer
