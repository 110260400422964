import development from './profiles/development'
import pro from './profiles/pro'
import tuv from './profiles/tuv'
import test from './profiles/tests'
import functionalTest from './profiles/functionalTest'

const config = {
  development,
  test,
  pro,
  tuv,
  functionalTest,
}

export default config[process.env.REACT_APP_PROFILE] || config[process.env.NODE_ENV]

export const isDevelopmentMode = () =>
  !isFunctionalTest() && (process.env.NODE_ENV === 'development' || process.env.REACT_APP_PROFILE === 'development')

export const isFunctionalTest = () => process.env.REACT_APP_PROFILE === 'functionalTest'
