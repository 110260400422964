import React, { FC } from 'react'
import { Tooltip, TooltipContainer, TooltipTarget } from 'is24-corecss'
import style from './IconWithToolTip.module.css'

type IconWithToolTipProps = {
  icon: string
  tooltip?: string
  nowrap?: boolean
}

const IconWithToolTip: FC<IconWithToolTipProps> = ({ icon, tooltip, nowrap }) => (
  <TooltipContainer className={style.toolTipContainer}>
    <TooltipTarget>
      <i className={icon} title={tooltip} data-testid='tooltip-icon' />
    </TooltipTarget>
    <Tooltip
      className={`margin-top-s padding-vertical-xs padding-horizontal-s ${nowrap === true ? 'font-nowrap' : ''}`}
      bodyPosition='bottom'
      arrowPosition='center'
    >
      <span className='font-white font-s font-normal margin-none' data-testid='tooltip-text'>
        {tooltip}
      </span>
    </Tooltip>
  </TooltipContainer>
)

export default IconWithToolTip
