import React, { FC, Fragment, ReactElement } from 'react'

import { useSelector } from 'react-redux'
import { type Lead } from '../../types/lead'
import { trackEvent } from '../../api/reporting'
import { formatDate, formatTime } from '../../helpers/lead'
import CancellationStateUI from './cancellationUI/CancellationStateUI'
import leadDetailsBuilderService, {
  type BasicLeadData,
  type DataSet,
  type RichLeadData,
  type Row,
} from './leadDetailsBuilderService'
import { ValuationPdfSection } from './ValuationPdfSection'

type RequiredState = {
  leads: {
    leads: Array<Lead>
    indexOfClickedLead: number
  }
}

const ModalContent: FC = () => {
  const pageName = 'lead-details'

  const lead = useSelector((state: RequiredState) => state.leads.leads[state.leads.indexOfClickedLead])

  const reportContactLink = (linkType: string): void => {
    if (linkType === 'tel') {
      trackEvent(pageName, 'click_owner-telephone-link')
    } else {
      trackEvent(pageName, 'click_owner-mail-link')
    }
  }

  const renderContactLink = (
    link: string | null | undefined,
    linkType: string,
    testId: string,
  ): ReactElement | null => {
    if (!link) {
      return null
    }

    if (link === 'Anonymisiert') {
      return <span className='margin-right'>Anonymisiert</span>
    }

    return (
      <Fragment>
        <a
          className='margin-right'
          onClick={() => reportContactLink(linkType)}
          data-testid={testId}
          href={`${linkType}:${link}`}
        >
          {link}
        </a>
      </Fragment>
    )
  }

  const renderTopSection = (lead: Lead): ReactElement => {
    return (
      <>
        <div>
          {formatDate(lead.createdDate)} <span className='palm-hide'>{formatTime(lead.createdDate)}</span>
          <span className='margin-left-xxl'>{lead.leadData.salesforceId ?? '-'}</span>
        </div>

        <div className='margin-top-8'>
          <CancellationStateUI lead={lead} />
        </div>
      </>
    )
  }

  const renderContactData = (leadData: BasicLeadData | RichLeadData): ReactElement => {
    return (
      <div>
        <div className='section-header'>Kontaktdaten</div>
        <div>{leadData.name}</div>
        <div data-testid='contact-links' className='margin-top-8'>
          {renderContactLink(leadData.phone, 'tel', 'phone-link-1')}
          {renderContactLink('phoneAlternative' in leadData ? leadData.phoneAlternative : null, 'tel', 'phone-link-2')}
          {renderContactLink(leadData.email, 'mailto', 'email-link')}
        </div>
        <div>
          {'isOwner' in leadData && leadData.isOwner && (
            <div className='grid margin-top-16'>
              <div className='grid-item one-fourth palm-one-half name-cell'>Eigentümer:</div>
              <div className='grid-item one-fourth palm-one-half'>{leadData.isOwner}</div>
            </div>
          )}
          {'availability' in leadData && leadData.availability && (
            <div className='grid margin-top-8'>
              <div className='grid-item one-fourth palm-one-half name-cell'>Erreichbarkeit:</div>
              <div className='grid-item one-fourth palm-one-half'>{leadData.availability}</div>
            </div>
          )}
          {'userIntent' in leadData && leadData.userIntent && (
            <div className='grid margin-top-8'>
              <div className='grid-item one-fourth palm-one-half name-cell'>Ziel:</div>
              <div className='grid-item one-fourth palm-one-half'>{leadData.userIntent}</div>
            </div>
          )}
          {'contactAvailability' in leadData && leadData.contactAvailability && (
            <div className='grid margin-top-8'>
              <div className='grid-item one-fourth palm-one-half name-cell'>Erreichbarkeit</div>
              <div className='grid-item one-fourth palm-one-half'>{leadData.contactAvailability}</div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderObjectData = (leadData: BasicLeadData | RichLeadData): ReactElement => {
    return (
      <div>
        <div className='section-header'>Objekt</div>
        <div className='section-address'>
          <span className='is24-icon-location' />
          {leadData.objectDetails.address}
        </div>

        <ValuationPdfSection />

        {leadData.objectDetails.data.map((dataSet: DataSet, index: number) => {
          if (dataSet.rows.length === 0) {
            return <Fragment key={index} />
          }
          return (
            <div key={index} className='object-section-box'>
              <div className='font-bold section-rows-header palm-hide'>{dataSet.label}</div>
              <div className='section-rows'>
                <div className='grid grid-flex'>
                  {dataSet.rows.map((row: Row, index: number) => {
                    return (
                      <Fragment key={index}>
                        <div className='grid-item one-half name-cell'>{row.name}:</div>
                        <div className='grid-item one-half'>{row.value}</div>
                      </Fragment>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
        {leadData.objectDetails.extendedData.map((row: Row, index: number) => {
          return (
            <div key={index} className='extended-data-section'>
              <div className='extended-data-section-name name-cell'>{row.name}</div>
              <div className='extended-data-section-value'>{row.value}</div>
            </div>
          )
        })}
      </div>
    )
  }

  const leadData = leadDetailsBuilderService(
    lead.leadType,
    lead.gdprDeleted,
    lead.requester,
    lead.realEstate,
    lead.leadData,
  )

  return (
    <div data-testid='modal-content' className='modal-content lead-detail-view-container'>
      {renderTopSection(lead)}
      {renderContactData(leadData)}
      {renderObjectData(leadData)}
    </div>
  )
}

export { ModalContent }
