import React, { FC } from 'react'
import { type Lead } from '../../../types/lead'
import { DesktopAndTablet, Mobile } from '../../MediaComponents/MediaComponents'
import LapAndDeskLeadRow from './LapAndDeskLeadRow'
import MobileLeadRow from './MobileLeadRow'

type LeadRowProps = {
  lead: Lead
  index: number
  hideLead: boolean
  fadeOut: boolean
  onClick: (index: number) => void
}

const LeadRow: FC<LeadRowProps> = ({ lead, index, hideLead, fadeOut, onClick }) => {
  return (
    <React.Fragment>
      <DesktopAndTablet
        render={() => (
          <LapAndDeskLeadRow fadeOut={fadeOut} hideLead={hideLead} index={index} lead={lead} onClick={onClick} />
        )}
      />
      <Mobile
        render={() => (
          <MobileLeadRow lead={lead} index={index} hideLead={hideLead} fadeOut={fadeOut} onClick={onClick} />
        )}
      />
    </React.Fragment>
  )
}

export default LeadRow
