export interface Lead {
  assignmentId?: number
  leadType: string | null
  createdDate?: string | null
  gdprDeleted: boolean
  status?: string | null
  cancellable?: boolean
  payPerConverted?: boolean
  payPerConvertedIsInvoiced?: boolean
  cancellationReason?: string | null
  cancellationReasonDetail?: string | null
  cockpitName?: string
  requester: Requester
  realEstate: RealEstate
  leadData: LeadData
}

export enum UserIntent {
  MAKLER_SALE = 'MAKLER_SALE', // = 'Verkauf mit Makler',
  RENT = 'RENT', // = 'Miete',
  BUY = 'BUY', // = 'Kauf',
  LET = 'LET', // = 'Vermieten',
}

export enum SolarUserIntent {
  BUY = 'BUY', // = 'Kauf',
  RENT = 'RENT', // = 'Miete',
  BOTH = 'BOTH', // 'Beides interessant'],
  NOT_SURE = 'NOT_SURE', //'Weiß ich nicht'],
}

export interface Address {
  postcode?: string | null
  city?: string | null
  street?: string | null
  houseNumber?: string | null
}

export enum RealEstateType {
  APARTMENT = 'APARTMENT', // = 'Wohnung',
  HOUSE = 'HOUSE', // = 'Haus',
  LAND = 'LAND', // = 'Grundstück',
  COMMERCIAL = 'COMMERCIAL', // = 'Gewerbe',
  PACKAGE = 'PACKAGE', // = 'Immobilienpaket',
  GARAGE_PARKING_SPACE = 'GARAGE_PARKING_SPACE', // = 'PKW-Stellplatz / Garage',
}

export enum PlacementType {
  APARTMENT = 'APARTMENT',
  SINGLE_FAMILY_HOME = 'SINGLE_FAMILY_HOME',
  TWO_FAMILY_HOME = 'TWO_FAMILY_HOME',
  ONE_OR_TWO_FAMILY_HOME = 'ONE_OR_TWO_FAMILY_HOME',

  HOUSE = 'HOUSE',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE',

  COMMERCIAL = 'COMMERCIAL',
  OTHER = 'OTHER',
}

export enum BatteryStorageType {
  YES = 'YES',
  NO = 'NO',
}

export enum WallboxType {
  YES = 'YES',
  NO = 'NO',
}

export enum InsulationType {
  FACADE_INSULATION = 'FACADE_INSULATION',
  ROOF_INSULATION = 'ROOF_INSULATION',
  DOORS_AND_WINDOWS = 'DOORS_AND_WINDOWS',
  BASEMENT_INSULATION = 'BASEMENT_INSULATION',
  NO = 'NO',
}

export enum EnergySourceType {
  GAS = 'GAS',
  HEATING_OIL = 'HEATING_OIL',
  WOOD = 'WOOD',
  DISTRICT_HEATING = 'DISTRICT_HEATING',
}

export enum HeatingType {
  RADIATOR = 'RADIATOR',
  UNDERFLOOR_HEATING = 'UNDERFLOOR_HEATING',
  COMBINATION = 'COMBINATION',
  OTHER = 'OTHER',
}

export enum MonumentProtectionType {
  YES = 'YES',
  NO = 'NO',
}

export enum ResidentialUsageType {
  YES = 'YES',
  NO = 'NO',
}

export enum HouseholdSizeType {
  ONE_OR_TWO = 'ONE_OR_TWO',
  THREE = 'THREE',
  FOUR = 'FOUR',
  FIVE_PLUS = 'FIVE_PLUS',
}

export enum DevelopmentPotential {
  DEVELOPED = 'DEVELOPED', // = 'Kurzfristig bebaubar',
  PARTIALLY_DEVELOPED = 'PARTIALLY_DEVELOPED', // = 'Eingeschränkt bebaubar',
  UNDEVELOPED = 'UNDEVELOPED', // = 'Nicht bebaubar',
  UNKNOWN = 'UNKNOWN', // = 'Weiß nicht',
}

export interface ValuationRange {
  min: number
  max: number
}

export interface Valuation {
  value: number
  range: ValuationRange
}

export enum RealEstateSubType {
  BASEMENT = 'BASEMENT', // = 'Keller',
  ATTIC = 'ATTIC', // = 'Dachgeschoss',
  GROUND_FLOOR = 'GROUND_FLOOR', // = 'Erdgeschoss',
  DUPLEX = 'DUPLEX', // = 'Maisonette',
  APARTMENT = 'APARTMENT', // = 'Etagenwohnung',
  LOFT = 'LOFT', // = 'Loft',
  SOUTERRAIN = 'SOUTERRAIN', // = 'Souterrain',

  // House
  DETACHED_HOUSE = 'DETACHED_HOUSE', // = 'Einfamilienhaus',
  SEMI_DETACHED_HOUSE = 'SEMI_DETACHED_HOUSE', // = 'Doppelhaushälfte',
  TERRACED_MIDDLE_HOUSE = 'TERRACED_MIDDLE_HOUSE', // = 'Reihenmittelhaus',
  TERRACED_END_HOUSE = 'TERRACED_END_HOUSE', // = 'Reihenendhaus',
  MULTI_FAMILY_HOUSE = 'MULTI_FAMILY_HOUSE', // = 'Mehrfamilienhaus',

  // Commercial
  OFFICE = 'OFFICE', // = 'Büro oder Praxis',
  HALL = 'HALL', // = 'Halle oder Produktion',
  RETAIL = 'RETAIL', // = 'Einzelhandel',
  GASTRONOMY = 'GASTRONOMY', // = 'Gastronomie oder Hotel',
  MISCELLANEOUS = 'MISCELLANEOUS', // = 'Sonstiges',

  // Immoverkauf24
  TWO_OR_THREE_FAMILY_HOUSE = 'TWO_OR_THREE_FAMILY_HOUSE', // = 'Zwei- oder Dreifamilienhaus',
  BUILDING_PLOT = 'BUILDING_PLOT', // = 'Baugrundstück',
  COMMERCIAL_LAND = 'COMMERCIAL_LAND', // = 'Gewerbegrundstück',
  AGRICULTURAL_LAND = 'AGRICULTURAL_LAND', // = 'Agrarfläche',
  GARDEN_PLOT = 'GARDEN_PLOT', // = 'Gartengrundstück',
  BUILDING_LAND = 'BUILDING_LAND', // = 'Bauerwartungsland',
  MEADOW = 'MEADOW', // = 'Wiese',
  RESIDENTIAL_AND_COMMERCIAL_BUILDING = 'RESIDENTIAL_AND_COMMERCIAL_BUILDING', // = 'Wohn- und Geschäftshaus',
  HOTEL = 'HOTEL', // = 'Gastronomie oder Hotel',
  GASTRONOMY_IV24 = 'GASTRONOMY_IV24', // = 'Gastronomie oder Hotel',
  FORMER_FARMHOUSE = 'FORMER_FARMHOUSE', // = 'Resthof',
  COMMERCIAL_PROPERTY = 'COMMERCIAL_PROPERTY', // = 'Gewerbeimmobilie',
  CONDOMINIUMS = 'CONDOMINIUMS', // = 'Eigentumswohnungen',
  GARAGE = 'GARAGE', // = 'Garage',
  UNDERGROUND_PARKING_SPACE = 'UNDERGROUND_PARKING_SPACE', // = 'Tiefgaragenstellplatz',
  CARPORT = 'CARPORT', // = 'Carport',
  PARKING_SPACE = 'PARKING_SPACE', // = 'Stellplatz',
}

export enum DevelopmentStatus {
  FULL_MEDIA = 'FULL_MEDIA', // "Erschlossen"
  PARTIAL_MEDIA = 'PARTIAL_MEDIA', // "Teilerschlossen"
  NO_MEDIA = 'NO_MEDIA', // "Unerschlossen"
}

export enum Facility {
  BALCONY = 'BALCONY',
  GARAGE = 'GARAGE',
  ELEVATOR = 'ELEVATOR',
  BASEMENT = 'BASEMENT',
  TERRACE = 'TERRACE',
}

export enum Furnishing {
  SIMPLE = 'SIMPLE', // = 'Einfach',
  NORMAL = 'NORMAL', // = 'Normal',
  HIGH_QUALITY = 'HIGH_QUALITY', // = 'Gehoben',
  LUXURIOUS = 'LUXURIOUS', // = 'Luxus',
}

export enum LandLayout {
  CORNER_PROPERTY = 'CORNER_PROPERTY', // = 'Eckgrundstück',
  RECTANGULAR_FORMAT = 'RECTANGULAR_FORMAT', // = 'Rechteckiger Zuschnitt',
  OTHER = 'OTHER', // = 'Sonstiges',
}

export enum UsageStatus {
  OWNER_OCCUPIED = 'OWNER_OCCUPIED', // = 'Selbstgenutzt',
  RENTED = 'RENTED', // = 'Vermietet',
  VACANT = 'VACANT', // = 'Leer stehend',
  PARTLY_RENTED = 'PARTLY_RENTED', // = 'teilweise vermietet',
}

export enum Basement {
  YES = 'YES', //  "Ja"
  NO = 'NO', //  "Nein"
  AVAILABLE = 'AVAILABLE', //  "vorhanden"
  PARTIAL_BASEMENT = 'PARTIAL_BASEMENT', //  "teilunterkellert"
  FULL_BASEMENT = 'FULL_BASEMENT', //  "vollunterkellert"
  NOT_AVAILABLE = 'NOT_AVAILABLE', //  "nicht vorhanden"
  NOT_SPECIFIED = 'NOT_SPECIFIED', //  "keine Angabe"
}

export interface RealEstate {
  address: Address
  realEstateType: RealEstateType
  constructionYear: number | null
  bathroomInformation: string | null
  builtInKitchen: string | null
  developmentPotential: DevelopmentPotential | null
  developmentStatus: DevelopmentStatus | null
  facilities: Array<Facility>
  floor: number | null
  furnishing: Furnishing | null
  landLayout: LandLayout | null
  livingSpace: number | null
  numberOfRooms: number | null
  propertyArea: number | null
  subType: RealEstateSubType | null
  valuation: Valuation | null
  commercialLandAreaEnabled: boolean
  netYearRent: number | null
  balconyPatio: boolean | null
  usageStatus: UsageStatus | null
  baseRent: number | null
  basement: Basement | null
  parkingSpace: string | null
  windows: string | null
  heating: string | null
  roof: string | null
  specialAmenities: string | null
  floorsHouse: number | null
  furtherRealEstateInformation: string | null
}

export interface Requester {
  salutation: Salutation
  firstName: string | null
  lastName: string | null
  email: string | null
  phoneNumber: string | null
  secondaryPhoneNumber: string | null
  isPhoneNumberValid: boolean | null
}

export interface SolarLeadData {
  placement: PlacementType | null | undefined
  userIntent?: SolarUserIntent | null
  batteryStorage: BatteryStorageType | null | undefined
  wallBox: WallboxType | null | undefined
  postcode: string | null
  selectedCustomerIds: string[] | null | undefined
  energyConsumption: number | null
  householdSize: HouseholdSizeType | null | undefined
}

export interface EnergyAdvisorLeadData {
  placement: PlacementType | null | undefined
  postcode: string | null
  constructionYear: number | null
  monumentProtection: MonumentProtectionType | null | undefined
  residentialUsage: ResidentialUsageType | null | undefined
  eligibleForDiscount: boolean | null | undefined
}

export interface HeatingLeadData {
  placement: PlacementType | null | undefined
  heatingInstallationYear: number | null
  insulation: InsulationType[] | null | undefined
  energySource: EnergySourceType | null | undefined
  heating: HeatingType | null | undefined
  heatedSpaceSize: number | null | undefined
  selectedCustomerIds: string[] | null | undefined
}

export interface WallWindowLeadData {
  requesterType: RequesterType | null
}

export interface RoofWindowLeadData {
  roofType: RoofType | null | undefined
  windowAmount: WindowAmount | null | undefined
  windowPaneCount: WindowPaneCount | null | undefined
  windowDisposal: WindowDisposal | null | undefined
}

export enum OwnerType {
  YES = 'YES', // = 'Ja',
  NO = 'NO', // = 'Nein',
  CO_OWNER = 'CO_OWNER', // = 'Ich bin Teil-Eigentümer',
  RELATIVE = 'RELATIVE', // = 'Ich bin Angehöriger',
}

export interface LeadData {
  salesforceId: string
  ownerType: OwnerType
  userIntent: UserIntent
  ownerAvailability: string | null
  contactAvailability: string | null
  others: string | null
  solarLeadData?: SolarLeadData | null | undefined
  energyAdvisorLeadData?: EnergyAdvisorLeadData | null | undefined
  heatingLeadData?: HeatingLeadData | null | undefined
  wallWindowLeadData?: WallWindowLeadData | null | undefined
  roofWindowLeadData?: RoofWindowLeadData | null | undefined
}

export enum Salutation {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  DIVERS = 'DIVERS',
}

export enum RoofType {
  STEEP_ROOF_GABLE = 'STEEP_ROOF_GABLE',
  STEEP_ROOF_HIP = 'STEEP_ROOF_HIP',
  FLAT_ROOF = 'FLAT_ROOF',
  NOT_SURE = 'NOT_SURE',
}

export enum ServiceType {
  DELIVERY = 'DELIVERY',
  DELIVERY_AND_INSTALLATION = 'DELIVERY_AND_INSTALLATION',
  NOT_SURE = 'NOT_SURE',
}

export enum WindowAmount {
  ONE = 'ONE',
  TWO_TO_FOUR = 'TWO_TO_FOUR',
  MORE_THAN_FOUR = 'MORE_THAN_FOUR',
  NOT_SURE = 'NOT_SURE',
}

export enum WindowPaneCount {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  TRIPLE = 'TRIPLE',
  NOT_SURE = 'NOT_SURE',
}

export enum WindowDisposal {
  YES = 'YES',
  NO = 'NO',
  NOT_SURE = 'NOT_SURE',
}

export enum RequesterType {
  PRIVATE = 'PRIVATE',
  COMMERCIAL = 'COMMERCIAL',
  NOT_SURE = 'NOT_SURE',
}
