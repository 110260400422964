import React, { FC } from 'react'
import { Font } from 'is24-corecss'
import styles from './MobileLeadRow.module.css'
import { formatDate, getLeadTypeIcon, getShortAddressStr, getSolarLeadPLZ } from '../../../helpers/lead'
import { type Lead } from '../../../types/lead'
import LeadStatus from '../../leadStatus/LeadStatus'
import StatusCell from '../tableCells/StatusCell'
import { LeadType } from '../../../enums/LeadType'

type LeadRowProps = {
  lead: Lead
  index: number
  hideLead: boolean
  fadeOut: boolean
  onClick: (index: number) => void
}

const LeadRow: FC<LeadRowProps> = ({ lead, index, hideLead, fadeOut, onClick }) => {
  const type = lead.leadType
  const cockpitName = lead.cockpitName
  const requester = lead.requester
  const createdDate = formatDate(lead.createdDate)
  const gdprDeleted = lead.gdprDeleted

  const status = lead['status'] ? lead['status'] : ''

  return (
    <div
      data-testid={`mobile-lead-${index}`}
      className={` ${hideLead ? 'hide' : ''} ${fadeOut ? styles.fadeOut : ''}`}
      key={index}
      onClick={() => onClick(index)}
    >
      <span className={`${styles.separator}`} />
      <div className='grid grid-flex grid-justify-space-between'>
        <div className='grid-item one-half'>
          <Font className='font-m' weight='bold'>
            {gdprDeleted ? requester.firstName : `${requester.firstName} ${requester.lastName}`}
          </Font>
        </div>
        <div className={`${styles.subtext}  grid-item one-half `}>
          <Font className={`${styles.subtext} align-right margin-top-s`} weight='normal'>
            {createdDate}
          </Font>
        </div>
      </div>
      <div className={`grid grid-flex  margin-bottom-s`}>
        <div className='grid-item one-whole'>
          <Font className={`align-left`} weight='normal'>
            <i className={`${styles.leadTypeIcon} ${getLeadTypeIcon(type)}`} />
            <span className={`${styles.subtext} ${styles.leadTypeText}`}>{cockpitName}</span>
            <span className={`${styles.subtext}`} data-testing='shortAddress'>
              {type === LeadType.SOLAR ? getSolarLeadPLZ(lead) : getShortAddressStr(lead)}
            </span>
          </Font>
        </div>
        <div className={`grid-item padding-vertical-l`}>
          <StatusCell>
            <LeadStatus value={status} />
          </StatusCell>
        </div>
      </div>
    </div>
  )
}

export default LeadRow
