import { createAsyncThunk } from '@reduxjs/toolkit'
import { isDevelopmentMode } from '../../config'
import { sleep } from '../../helpers/shared'
import { oauthProtectedRequest } from '../../api/fetch'
import { type ReduxState } from '../reducer'

export enum ValuationPdfErrorType {
  ERROR = 'ERROR',
  PDF_MISSING = 'PDF_MISSING',
}

const DUMMY_PDF_URL = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'

export const fetchValuationPdfUrl = createAsyncThunk<
  string,
  { assignmentId: number; httpRequestMethod: string },
  {
    rejectValue: ValuationPdfErrorType
  }
>('valuationPdf/fetchPdfUrl', async ({ assignmentId, httpRequestMethod }, thunkAPI) => {
  try {
    if (isDevelopmentMode()) {
      await sleep(2000)
      const shouldReturnPdfMissing: boolean = Math.random() < 0.5
      return shouldReturnPdfMissing ? DUMMY_PDF_URL : thunkAPI.rejectWithValue(ValuationPdfErrorType.PDF_MISSING)
    } else {
      const accessToken = (thunkAPI.getState() as ReduxState).auth.token as string
      const url = `/api/customercockpit/pdf?assignmentId=${assignmentId}`
      const response = await oauthProtectedRequest(
        url,
        {
          method: httpRequestMethod,
          headers: {
            'Content-Type': 'application/json',
          },
        },
        accessToken,
      )

      if (response?.ok) {
        const data = await response.json()
        return data.uri
      }

      const statusCode = response?.status
      if (!statusCode || statusCode >= 500) {
        return thunkAPI.rejectWithValue(ValuationPdfErrorType.ERROR)
      }
      return thunkAPI.rejectWithValue(ValuationPdfErrorType.PDF_MISSING)
    }
  } catch {
    return thunkAPI.rejectWithValue(ValuationPdfErrorType.ERROR)
  }
})
