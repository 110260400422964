import React, { Component } from 'react'
import { Font } from 'is24-corecss'
import './FallbackMessageNoLeads.css'

class FallbackMessageNoLeads extends Component {
  render() {
    return (
      <div className='fallbackmsg'>
        <Font className='title palm-font-xxl lap-font-xxl desk-font-xxl' weight='normal'>
          Schade!
        </Font>
        <Font className='sub-title lap-font-l desk-font-l' weight='normal'>
          Es sind keine Eigentümeranfragen verfügbar.
        </Font>
      </div>
    )
  }
}

export default FallbackMessageNoLeads
