import React from 'react'
import PropTypes from 'prop-types'

const TableCell = ({ className, children }) => <div className={`tableCell ${className}`}>{children}</div>

TableCell.propTypes = {
  className: PropTypes.string,
}

export default TableCell
