import React, { CSSProperties, FC } from 'react'
import { Font } from 'is24-corecss'
import style from './EyeCatcher.module.css'

type EyeCatcherType = {
  count: number
}

const counterStyle = (count: number): CSSProperties => {
  const numberOfDigits = count.toString().length - 1
  const margin = 30 - numberOfDigits * 4.6
  return { marginLeft: `${margin}px` }
}

const EyeCatcher: FC<EyeCatcherType> = (props) => {
  return (
    <div className={style.eyeCatcher}>
      <Font style={counterStyle(props.count)} className={`${style.leadsCounter} font-xl`} weight='normal'>
        {props.count} Anfragen
      </Font>
    </div>
  )
}

export default EyeCatcher
