import React from 'react'
import './CancellationNotification.css'

const CancellationNotification = ({ onClose }) => (
  <div className='cancellationNotificationModal shadow'>
    <div className='grid grid-flex'>
      <div className='grid-item one-tenth align-center'>
        <span className='is24-icon-accept cancellationNotificationLogo' />
      </div>
      <div className='grid-item eight-tenth padding-left-m'>
        <p className='cancellationNotificationHeading'>Reklamation eingereicht</p>
        <p className='cancellationNotificationInfo'>Ihre Reklamation wurde versendet!</p>
      </div>
      <div className='cancellationNotificationClose grid-item one-tenth'>
        <span className='is24-icon-closing' onClick={onClose} data-testid='cancellation-close-icon' />
      </div>
    </div>
  </div>
)

export default CancellationNotification
