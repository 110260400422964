import React, { Component } from 'react'
import './Loading.css'

class Loading extends Component {
  render() {
    return (
      <div className='loading-screen'>
        <i className='fa fa-spinner fa-spin fa-5x' />
      </div>
    )
  }
}

export default Loading
