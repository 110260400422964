import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface LeadDetailViewUi {
  isModalVisible: boolean
}

export const initialLeadDetailViewUiState: LeadDetailViewUi = {
  isModalVisible: false,
}

const leadDetailViewUiSlice = createSlice({
  name: 'leadDetailViewUi',
  initialState: initialLeadDetailViewUiState,
  reducers: {
    setIsModalVisible: (state: LeadDetailViewUi, action: PayloadAction<boolean>) => {
      state.isModalVisible = action.payload
    },
  },
})

export const { setIsModalVisible } = leadDetailViewUiSlice.actions

export default leadDetailViewUiSlice.reducer
