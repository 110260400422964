import React, { FC } from 'react'
import Badge from '../badge/Badge'
import { leadStatus } from '../../enums/LeadStatus'

type LeadStatusMap = {
  [key in leadStatus]: string
}

const longText: LeadStatusMap = {
  [leadStatus.CANCELLATION_IN_PROGRESS]: 'Reklamation beantragt',
  [leadStatus.CANCELLATION_APPROVED]: 'Reklamiert',
  [leadStatus.CANCELLATION_REJECTED]: 'Reklamation abgelehnt',
  [leadStatus.MANDATE]: 'Auftrag',
  [leadStatus.ON_SITE_APPOINTMENT]: 'Termin vor Ort',
  [leadStatus.PHONE_CONTACT]: 'Telefonischer Kontakt',
  [leadStatus.SOLD]: 'Verkauft',
}

const shortText: LeadStatusMap = {
  [leadStatus.CANCELLATION_IN_PROGRESS]: 'Reklamation beantragt',
  [leadStatus.CANCELLATION_APPROVED]: 'Reklamiert',
  [leadStatus.CANCELLATION_REJECTED]: 'Reklamation abgelehnt',
  [leadStatus.MANDATE]: 'Auftrag',
  [leadStatus.ON_SITE_APPOINTMENT]: 'Termin vor Ort',
  [leadStatus.PHONE_CONTACT]: 'Telefonischer Kontakt',
  [leadStatus.SOLD]: 'Verkauft',
}

const getText = (value: leadStatus, isLong: boolean): string => (isLong ? longText[value] : shortText[value])

type LeadStatusProps = {
  value: string
  isLong?: boolean
}

const LeadStatus: FC<LeadStatusProps> = ({ value, isLong = false }) => {
  switch (value) {
    case leadStatus.CANCELLATION_IN_PROGRESS:
      return (
        <Badge className='badge--orange' testid='lead-status-badge'>
          {getText(value, isLong)}
        </Badge>
      )
    case leadStatus.CANCELLATION_APPROVED:
      return (
        <Badge className='badge--grey' testid='lead-status-badge'>
          {getText(value, isLong)}
        </Badge>
      )
    case leadStatus.CANCELLATION_REJECTED:
      return (
        <Badge className='badge--red' testid='lead-status-badge'>
          {getText(value, isLong)}
        </Badge>
      )
    case leadStatus.MANDATE:
      return (
        <Badge className='badge--blue' testid='lead-status-badge'>
          {getText(value, isLong)}
        </Badge>
      )
    case leadStatus.ON_SITE_APPOINTMENT:
      return (
        <Badge className='badge--blue' testid='lead-status-badge'>
          {getText(value, isLong)}
        </Badge>
      )
    case leadStatus.PHONE_CONTACT:
      return (
        <Badge className='badge--blue' testid='lead-status-badge'>
          {getText(value, isLong)}
        </Badge>
      )
    case leadStatus.SOLD:
      return (
        <Badge className='badge--teal' testid='lead-status-badge'>
          {getText(value, isLong)}
        </Badge>
      )
    default:
      return null
  }
}

export default LeadStatus
