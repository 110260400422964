import { LeadType } from '../enums/LeadType'
import { numberFormatter } from './numberFormatter'
import { Lead, RealEstateType, Valuation } from '../types/lead'

export const formatValuation = (leadType: string | null, valuation: Valuation | null): string => {
  if (valuation == null) return '-'
  if (valuation.range.max === 0 && valuation.range.min === 0) return '-'

  switch (leadType) {
    case 'RICH':
    case 'COMMISSION':
    case 'B':
      return `${numberFormatter.format(valuation.value)}`
    default:
      return `${numberFormatter.format(valuation.range.min)} - ${numberFormatter.format(valuation.range.max)}`
  }
}

export const getPropertyTypeIcon = (type: RealEstateType): string => {
  switch (type) {
    case RealEstateType.HOUSE:
      return 'is24-icon-building-single-family-house'
    case RealEstateType.APARTMENT:
      return 'is24-icon-apartment-house'
    case RealEstateType.LAND:
      return 'is24-icon-property'
    case RealEstateType.COMMERCIAL:
      return 'is24-icon-building-new-building'
    default:
      return ''
  }
}

export const getLeadTypeIcon = (type: string | null): string => {
  switch (type) {
    case LeadType.BASIC:
      return 'is24-icon-application-documents'
    case LeadType.RICH:
      return 'is24-icon-owner-plus'
    case LeadType.LAND_WITHOUT_REALTOR:
      return 'is24-icon-price-per-squaremeter'
    case LeadType.WITHOUT_REALTOR:
      return 'is24-icon-land-plot-house-build'
    case LeadType.FREE_TO_LIST:
      return 'is24-icon-text-aligned-left'
    case LeadType.B:
      return 'is24-icon-star-loupe'
    case LeadType.COMMERCIAL:
      return 'is24-icon-building-new-building'
    case LeadType.BUYER:
      return 'is24-icon-price-per-squaremeter'
    case LeadType.SOLAR:
      return 'is24-icon-solar-potential'
    case LeadType.ENERGY_ADVISOR:
      return 'is24-icon-notebook'
    case LeadType.HEATING:
      return 'is24-icon-property'
    case LeadType.WALL_WINDOW:
      return 'is24-icon-house-loft'
    case LeadType.ROOF_WINDOW:
      return 'is24-icon-house-loft'
    case LeadType.LICENSE:
      return 'is24-icon-owner-plus'
    default:
      return ''
  }
}

export const formatDate = (dateString?: string | null) => {
  const date = new Date(dateString ?? new Date())

  if (isNaN(date.getDate())) return '-'

  const dd = date.getDate()

  const mm = date.getMonth() + 1

  const yyyy = date.getFullYear()

  return padLeadingZero(dd) + '.' + padLeadingZero(mm) + '.' + yyyy
}

export const formatTime = (timeString?: string | null): string => {
  const date = new Date(timeString ?? new Date())
  return `${padLeadingZero(date.getHours())}:${padLeadingZero(date.getMinutes())} Uhr`
}

export const totalOfPages = (leads: Lead[] | null, itemsPerPage: number): number => {
  if (leads) {
    return Math.ceil(leads.length / itemsPerPage)
  }
  return 0
}

export const padLeadingZero = (n: number) => (n < 10 ? '0' + n : n)

export const getFullAddressStr = (lead: Lead) => {
  const { leadType: type, gdprDeleted, realEstate } = lead
  const address =
    type === LeadType.RICH && !gdprDeleted ? `${realEstate.address.street} ${realEstate.address.houseNumber}` : ''

  return address.trim().length !== 0
    ? `${address}, ${realEstate.address.postcode} ${realEstate.address.city}`
    : `${realEstate.address.postcode} ${realEstate.address.city}`
}

export const getShortAddressStr = (lead: Lead): string => {
  const { realEstate } = lead

  return `${realEstate.address.postcode} ${realEstate.address.city}`
}

export const getSolarLeadPLZ = (lead: Lead): string => {
  return lead.realEstate !== null ? getShortAddressStr(lead) : `PLZ ${lead.leadData?.solarLeadData?.postcode}`
}
