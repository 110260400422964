import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { changeLeadStatus, loadLeads, cancelLead, setLeadAsConverted } from '../actions/leads'
import { type Lead } from '../../types/lead'

interface Leads {
  leads: Lead[]
  error: boolean
  isLoadingLeads: boolean
  indexOfClickedLead: number | null
  showChangeStatusErrorMessage: boolean
  isCancellationSubmissionInProgress: boolean
  cancellationErrorMessage: string
  showCancellationSuccessMessage: boolean
  isChangingLeadStatus: boolean
  isSettingConversionInProgress: boolean
  settingConversionErrorMessage: string
  showSettingConversionSuccessMessage: boolean
}

export const initialLeadsState: Leads = {
  leads: [],
  error: false,
  isLoadingLeads: false,
  indexOfClickedLead: null,
  showChangeStatusErrorMessage: false,
  isCancellationSubmissionInProgress: false,
  showCancellationSuccessMessage: false,
  isChangingLeadStatus: false,
  cancellationErrorMessage: '',
  isSettingConversionInProgress: false,
  settingConversionErrorMessage: '',
  showSettingConversionSuccessMessage: false,
}

const leadsSlice = createSlice({
  name: 'leads',
  initialState: initialLeadsState,
  reducers: {
    setIndexOfClickedLead: (state: Leads, action: PayloadAction<number | null>) => {
      state.indexOfClickedLead = action.payload
    },
    setShowCancellationSuccessMessage: (state: Leads, action: PayloadAction<boolean>) => {
      state.showCancellationSuccessMessage = action.payload
    },
    setLeads: (state: Leads, action: PayloadAction<Lead[]>) => {
      state.leads = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadLeads.pending, (state: Leads) => {
      state.isLoadingLeads = true
      state.error = false
    })
    builder.addCase(loadLeads.fulfilled, (state: Leads, action: PayloadAction<Lead[]>) => {
      state.isLoadingLeads = false
      state.leads = action.payload
      state.error = false
    })
    builder.addCase(loadLeads.rejected, (state: Leads) => {
      state.isLoadingLeads = false
      state.error = true
    })
    builder.addCase(changeLeadStatus.pending, (state: Leads) => {
      state.showChangeStatusErrorMessage = false
      state.isChangingLeadStatus = true
    })
    builder.addCase(
      changeLeadStatus.fulfilled,
      (state: Leads, action: PayloadAction<{ assignmentId: number; newStatus: string }>) => {
        const { assignmentId, newStatus } = action.payload
        const leads = state.leads
        const lead = leads.find((lead) => lead.assignmentId === assignmentId)
        if (lead) {
          lead.status = newStatus
        }
        state.showChangeStatusErrorMessage = false
        state.isChangingLeadStatus = false
      },
    )
    builder.addCase(changeLeadStatus.rejected, (state: Leads) => {
      state.showChangeStatusErrorMessage = true
      state.isChangingLeadStatus = false
    })
    builder.addCase(cancelLead.pending, (state: Leads) => {
      state.isCancellationSubmissionInProgress = true
      state.cancellationErrorMessage = ''
    })
    builder.addCase(cancelLead.fulfilled, (state: Leads, action: PayloadAction<Lead>) => {
      state.isCancellationSubmissionInProgress = false
      const cancelledLeadIndex = state.leads.findIndex((lead) => lead.assignmentId === action.payload.assignmentId)
      if (cancelledLeadIndex !== -1) {
        state.leads[cancelledLeadIndex] = action.payload
      }
      state.cancellationErrorMessage = ''
      state.showCancellationSuccessMessage = true
    })
    builder.addCase(cancelLead.rejected, (state: Leads, action: PayloadAction<string | undefined>) => {
      state.isCancellationSubmissionInProgress = false
      state.cancellationErrorMessage = action.payload ?? 'Unknown error'
    })
    builder.addCase(setLeadAsConverted.pending, (state: Leads) => {
      state.isSettingConversionInProgress = true
      state.settingConversionErrorMessage = ''
    })
    builder.addCase(setLeadAsConverted.fulfilled, (state: Leads, action: PayloadAction<Lead>) => {
      state.isSettingConversionInProgress = false
      const cancelledLeadIndex = state.leads.findIndex((lead) => lead.assignmentId === action.payload.assignmentId)
      if (cancelledLeadIndex !== -1) {
        state.leads[cancelledLeadIndex] = action.payload
      }
      state.settingConversionErrorMessage = ''
      state.showSettingConversionSuccessMessage = true
    })
    builder.addCase(setLeadAsConverted.rejected, (state: Leads, action: PayloadAction<string | undefined>) => {
      state.isSettingConversionInProgress = false
      state.settingConversionErrorMessage = action.payload ?? 'Unknown error'
    })
  },
})

export const { setIndexOfClickedLead, setShowCancellationSuccessMessage, setLeads } = leadsSlice.actions

export default leadsSlice.reducer
