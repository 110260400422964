import React, { Component } from 'react'
import { Button, SelectField, TextArea } from 'is24-corecss'
import './CancellationForm.css'

const MAX_LENGTH = 200
const MIN_LENGTH = 20

export enum CANCELLATION_REASON {
  DUPLICATE = 'DUPLICATE',
  INCORRECT_CONTACT_DETAILS = 'INCORRECT_CONTACT_DETAILS',
  CUSTOMER_TEST = 'CUSTOMER_TEST',
  SEEKER = 'SEEKER',
  OTHER = 'OTHER',
  SELECT_REASON = 'SELECT_REASON',
}

export const CANCELLATION_REASON_LABEL = {
  [CANCELLATION_REASON.DUPLICATE]: 'Duplikat',
  [CANCELLATION_REASON.INCORRECT_CONTACT_DETAILS]: 'Falsche Kontaktdaten',
  [CANCELLATION_REASON.CUSTOMER_TEST]: 'Maklertest',
  [CANCELLATION_REASON.SEEKER]: 'Suchender',
  [CANCELLATION_REASON.OTHER]: 'Sonstiges',
  [CANCELLATION_REASON.SELECT_REASON]: 'Reklamationsgrund wählen',
}

export type CancellationFormProps = {
  cancellationErrorMessage: string
  onCancel: () => unknown
  onSubmit: (cancellationReason: string, cancellationReasonDetail?: string) => unknown
  disabled: boolean
}

type CancellationFormState = {
  cancellationReason: string
  cancellationReasonDetails?: string
  characterCounter: number
}

export default class CancellationForm extends Component<CancellationFormProps, CancellationFormState> {
  constructor(props: CancellationFormProps) {
    super(props)
    this.state = {
      characterCounter: 0,
      cancellationReason: CANCELLATION_REASON.SELECT_REASON,
    }
  }

  textAreaInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      ...prevState,
      characterCounter: e.target.value.length,
      cancellationReasonDetails: e.target.value,
    }))
  }

  submitCancellation = () => {
    this.props.onSubmit(this.state.cancellationReason, this.state.cancellationReasonDetails)
  }

  handleReasonSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      ...prevState,
      cancellationReason: e.target.value,
    }))
  }

  render() {
    const isButtonEnabled =
      this.state.cancellationReason !== CANCELLATION_REASON.SELECT_REASON &&
      this.state.cancellationReasonDetails &&
      this.state.cancellationReasonDetails.length >= MIN_LENGTH

    const displayCancellationReasonTextArea = this.state.cancellationReason !== CANCELLATION_REASON.SELECT_REASON

    return (
      <div className='grid' data-testid='cancellation-dialog'>
        <div className='grid-item align-left desk-two-fifth lap-two-fifth palm-one-whole'>
          <SelectField label='' onChange={this.handleReasonSelected} data-testid='cancellation-reason-select'>
            <option data-testid='cancellation-reason-select-option' value={CANCELLATION_REASON.SELECT_REASON}>
              {CANCELLATION_REASON_LABEL.SELECT_REASON}
            </option>
            <option data-testid='cancellation-reason-select-option' value={CANCELLATION_REASON.DUPLICATE}>
              {CANCELLATION_REASON_LABEL.DUPLICATE}
            </option>
            <option
              data-testid='cancellation-reason-select-option'
              value={CANCELLATION_REASON.INCORRECT_CONTACT_DETAILS}
            >
              {CANCELLATION_REASON_LABEL.INCORRECT_CONTACT_DETAILS}
            </option>
            <option data-testid='cancellation-reason-select-option' value={CANCELLATION_REASON.CUSTOMER_TEST}>
              {CANCELLATION_REASON_LABEL.CUSTOMER_TEST}
            </option>
            <option data-testid='cancellation-reason-select-option' value={CANCELLATION_REASON.SEEKER}>
              {CANCELLATION_REASON_LABEL.SEEKER}
            </option>
            <option data-testid='cancellation-reason-select-option' value={CANCELLATION_REASON.OTHER}>
              {CANCELLATION_REASON_LABEL.OTHER}
            </option>
          </SelectField>
        </div>

        {displayCancellationReasonTextArea && (
          <div className='grid-item one-whole'>
            <div className='cancellationFormCounterWrapper'>
              <div className='cancellationFormCounter'>
                {this.state.characterCounter}/{MAX_LENGTH}
              </div>

              <div className='grid grid-flex'>
                <div className='grid-item one-whole'>
                  <TextArea
                    id='cancellation-form'
                    data-testid='cancellation-text-area'
                    label='Reklamationsgrund'
                    placeholder='Bitte beschreiben Sie den Grund der Reklamation'
                    maxLength={MAX_LENGTH}
                    onChange={this.textAreaInputHandler}
                    className='cancellationReason'
                    value={this.state.cancellationReasonDetails}
                    helperText='Die Mindestlänge beträgt 20 Zeichen'
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='grid-item margin-top-m'>
          <div className='grid grid-flex grid-align-center'>
            <Button
              data-testid='cancellation-button'
              appearance='secondary'
              disabled={!isButtonEnabled}
              onClick={this.submitCancellation}
              className={`grid-item ${
                displayCancellationReasonTextArea ? 'palm-push-one-fourth palm-three-fourths' : 'palm-one-whole'
              }`}
            >
              Anfrage Reklamieren
            </Button>
            <a
              data-testid='cancel-complaint-link-desk-lap'
              className='grid-item margin-left-l palm-hide'
              onClick={this.props.onCancel}
            >
              Reklamation abbrechen
            </a>
          </div>
        </div>
      </div>
    )
  }
}
