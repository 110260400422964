import { SelectField } from 'is24-corecss'
import React from 'react'
import './DropdownMonthPicker.css'
import { useAppDispatch } from '../../hooks/redux'
import { loadLeads } from '../../store/actions/leads'

export const months: Record<number, string> = {
  1: 'Januar',
  2: 'Februar',
  3: 'März',
  4: 'April',
  5: 'Mai',
  6: 'Juni',
  7: 'Juli',
  8: 'August',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'Dezember',
}

export const MONTH_LIMIT = 5
export const YEAR_LIMIT = 2019

export const getOptions = (fromDate: Date) => {
  const date = new Date(fromDate)
  date.setDate(1)

  const listOfOptions = []

  while (date.getMonth() !== MONTH_LIMIT || date.getFullYear() !== YEAR_LIMIT) {
    const currentMonth = date.getMonth() + 1
    listOfOptions.push(
      <option key={`${date.getFullYear()}${currentMonth}`} value={`${date.getFullYear()},${currentMonth}`}>
        {months[currentMonth] + ' ' + date.getFullYear()}
      </option>,
    )
    date.setMonth(date.getMonth() - 1)
  }

  return listOfOptions
}

interface DropdownMonthPickerProps {
  onChange?: () => void
}

const DropdownMonthPicker = ({ onChange }: DropdownMonthPickerProps) => {
  const dispatch = useAppDispatch()

  const handleSelection = (e: Event) => {
    const element = e.target as HTMLSelectElement
    const [year, month] = element.value.split(',')
    dispatch(loadLeads({ year, month }))

    if (onChange) {
      onChange()
    }
  }

  return (
    <SelectField id='monthPicker' label='' className='monthPicker' defaultValue='current' onChange={handleSelection}>
      {getOptions(new Date())}
    </SelectField>
  )
}

export default DropdownMonthPicker
