import React, { FC, ReactElement, useCallback } from 'react'
import { useFeatures } from '@paralleldrive/react-feature-toggles'
import { Button, Loader } from 'is24-corecss'
import './ValuationPdfSection.css'
import { RealEstateType } from '../../types/lead'
import { FEATURE_NAMES } from '../../feature/features'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { fetchValuationPdfUrl, ValuationPdfErrorType } from '../../store/actions/valuationPdf'

export const GENERATE_PDF_BUTTON_MESSAGE = 'Bewertungs-PDF generieren'
export const GENERATING_PDF_MESSAGE = 'Bewertungs-PDF wird generiert'

const ValuationPdfSection: FC = () => {
  const features = useFeatures()
  const lead = useAppSelector((state) => state.leads.leads[state.leads.indexOfClickedLead as number])
  const { valuationPdfUrl, valuationPdfErrorType, valuationPdfInProgress } = useAppSelector(
    (state) => state.valuationPdf,
  )

  const dispatch = useAppDispatch()

  const generateButtonClickHandler = useCallback(() => {
    dispatch(fetchValuationPdfUrl({ assignmentId: lead.assignmentId as number, httpRequestMethod: 'POST' }))
  }, [dispatch, lead.assignmentId])

  const isValuationPdfFeatureEnabled = (): boolean => features.includes(FEATURE_NAMES.VALUATION_PDF)

  const renderErrorMessage = (): ReactElement =>
    valuationPdfErrorType === ValuationPdfErrorType.ERROR ? (
      <span data-testid='valuation-pdf-error-text' className='valuation-pdf-error-text font-error'>
        <>
          Es konnte kein Bewertungs-PDF generiert werden. <br className='desk-hide' />
          Bitte versuchen Sie es in später erneut.
        </>
      </span>
    ) : (
      <></>
    )

  const renderDownloadLink = (): ReactElement | null => {
    const message = 'Bewertungs-PDF herunterladen'

    if (!valuationPdfUrl && !valuationPdfErrorType) {
      return (
        <span data-testid='pdf-download-link-disabled' className='disabled-text'>
          {message}
        </span>
      )
    }

    if (valuationPdfUrl) {
      return (
        <a data-testid='pdf-download-link' href={valuationPdfUrl} target='_blank' rel='noreferrer'>
          {message}
        </a>
      )
    }

    return null
  }

  const renderGenerateButton = (): ReactElement | null => {
    if (!valuationPdfErrorType) {
      return null
    }

    if (valuationPdfErrorType === ValuationPdfErrorType.ERROR) {
      return (
        <span className='disabled-text' data-testid='pdf-error-message'>
          {GENERATE_PDF_BUTTON_MESSAGE}
        </span>
      )
    }

    if (valuationPdfInProgress) {
      return (
        <>
          <span className='disabled-text' data-testid='pdf-loading-string'>
            {GENERATING_PDF_MESSAGE}
          </span>
          <div data-testid='pdf-loading-spinner' className='inline-block valuation-pdf-loader'>
            <Loader size='small' />
          </div>
        </>
      )
    }

    return (
      <Button
        id='pdf-generate-button'
        data-testid='pdf-generate-button'
        textStyle={true}
        onClick={generateButtonClickHandler}
      >
        {GENERATE_PDF_BUTTON_MESSAGE}
      </Button>
    )
  }

  if (
    lead.leadType !== 'RICH' ||
    !isValuationPdfFeatureEnabled() ||
    (lead.realEstate.realEstateType !== RealEstateType.HOUSE &&
      lead.realEstate.realEstateType !== RealEstateType.APARTMENT)
  ) {
    return null
  }

  return (
    <div className='section-valuation-pdf'>
      <div>
        <span className='is24-icon-pdf' />
        {renderDownloadLink()}
        {renderGenerateButton()}
        {renderErrorMessage()}
      </div>
    </div>
  )
}

export { ValuationPdfSection }
